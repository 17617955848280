import { RESET_USER_DATA, SET_AUTHENTICATION_STATUS, SET_LOGGED_IN_STATUS, SET_USER_DATA } from '../actionTypes';

const initialState = {
  userData: {},
  loginRedirect: false,
  isAuthenticated: false,
  isLoggedIn: false,
  loading: false
};

const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA:
      return { ...state, userData: payload };
    case SET_AUTHENTICATION_STATUS:
      return { ...state, isAuthenticated: payload };
    case SET_LOGGED_IN_STATUS:
      return { ...state, isLoggedIn: payload };
    case RESET_USER_DATA:
      return { ...state, userData: {}, isAuthenticated: false, isLoggedIn: false };
    default:
      return state;
  }
};

export default authReducer;
