import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_SIDEBAR_TYPES, ADD_COLOR, ADD_COSTOMIZER, ROUTER_ANIMATION } from '../../redux/actionTypes';
import { classes } from '../../data/layouts';

let DefaultLayout = {};
const Themecustomizer = () => {
  const default_color = localStorage.getItem('default_color');
  const secondary_color = localStorage.getItem('secondary_color');
  const layout_animation = localStorage.getItem('animation');
  const configDB = useSelector((content) => content.Customizer.customizer);
  const dispatch = useDispatch();
  const [layout_type, setLayout_type] = useState(configDB.settings.layout_type);
  // const [sidebar_setting, setSidebar_setting] = useState(configDB.settings.sidebar_setting);
  // console.log({ sidebar_setting, configDB });
  const mix_background_layout = configDB.color.mix_background_layout;
  const config_primary = configDB.color.primary_color;
  const config_secondary = configDB.color.secondary_color;

  useEffect(() => {
    handlePageLayouts(classes[0]);
    dispatch({ type: ADD_COSTOMIZER });

    dispatch({
      type: ADD_COLOR,
      payload: {
        default_color,
        secondary_color
      }
    });

    dispatch({ type: ROUTER_ANIMATION, payload: layout_animation });

    if (default_color == null || secondary_color == null) {
      document.documentElement.style.setProperty('--theme-deafult', config_primary);
      document.documentElement.style.setProperty('--theme-secondary', config_secondary);
      dispatch({
        type: ADD_COLOR,
        payload: {
          primary_color: config_primary,
          secondary_color: config_secondary
        }
      });
    } else {
      document.documentElement.style.setProperty('--theme-deafult', default_color);
      document.documentElement.style.setProperty('--theme-secondary', secondary_color);
      dispatch({
        type: ADD_COLOR,
        payload: {
          primary_color: default_color,
          secondary_color: secondary_color
        }
      });
    }

    //set layout_type
    document.body.classList.add(`${mix_background_layout}`);
    document.body.classList.add(`${layout_type}`);
    document.documentElement.dir = layout_type;
    //set sidebar_type

    // document.querySelector('.page-header').className = 'page-header';
    // document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper';

    document.querySelector('.page-wrapper').className = 'page-wrapper compact-wrapper';
    document.querySelector('.sidebar-wrapper').className = 'sidebar-wrapper close_icon';

    document.querySelector('.page-header').className = 'page-header close_icon';
    // }

    // document.querySelector('.sidebar-wrapper').setAttribute('sidebar-layout', sidebar_setting);
  }, []);

  const handlePageLayouts = (type) => {
    let key = Object.keys(type).pop();
    let val = Object.values(type).pop();
    document.querySelector('.page-wrapper').className = 'page-wrapper ' + val;
    dispatch({ type: ADD_SIDEBAR_TYPES, payload: { type: val } });
    localStorage.setItem('layout', key);
    // history(window.location.pathname);
  };

  return <Fragment></Fragment>;
};

export default Themecustomizer;
export { DefaultLayout };
