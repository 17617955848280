import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { isEmpty, isNil, isUndefined } from 'lodash';

export default function CustomInput({
  label,
  name,
  value = '',
  placeholder = '',
  isRequired = false,
  floating = false,
  readOnly = false,
  fieldOptions = {},
  onInputChange = null,
  inputStyles = { className: '', sx: {} },
  inputOptions = { type: 'text', multiple: false },
  labelOptions = { useLabel: true, useTopLabel: false },
  setInputError = { hasError: false },
  disabled = false
}) {
  const { t: translate } = useTranslation();
  const {
    register,
    formState: { errors, touchedFields },
    setValue,
    watch
  } = useFormContext();
  const fieldHasError = touchedFields[`${name}`] && isRequired && isEmpty(watch(name));
  const fieldValue = watch(name) || value;
  const showErrorFromSubmit = !isUndefined(errors[`${name}`]) && isEmpty(fieldValue);

  const handleOnChange = (e) => {
    setValue(name, e.target.value);
    onInputChange(e);
  };

  return (
    <>
      <FormGroup floating={floating} className={inputStyles.className} style={inputStyles.sx}>
        {!floating && labelOptions.useTopLabel && !labelOptions.useLabel ? <Label className="col-form-label">{translate(label)}</Label> : null}
        <Input
          multiple={inputOptions.multiple}
          className="form-control"
          type={inputOptions.type}
          value={fieldValue}
          {...register(name, { required: { value: isRequired, message: `${label} is required` }, ...fieldOptions })}
          placeholder={placeholder}
          invalid={setInputError.hasError || fieldHasError || showErrorFromSubmit}
          onChange={(e) => (!isNil(onInputChange) ? handleOnChange(e) : setValue(name, e.target.value))}
          readOnly={readOnly}
          onWheel={(e) => e.target.blur()}
          disabled={disabled}
        />
        <FormFeedback>{`${translate(errors[`${name}`]?.message)} `}</FormFeedback>
        {labelOptions.useLabel && !labelOptions.useTopLabel ? <Label for={name}>{`${translate(label)} ${labelOptions?.symbol || ''}`}</Label> : null}
      </FormGroup>
    </>
  );
}
