import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Input, Label, Button } from 'reactstrap';
import { Password, SignIn, EmailAddress, CreateAccount, PrivacyPolicy, StoreName, PhoneNumber, FirstName, LastName, StoreType } from '../../constant';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomInput from '../../components/forms/CustomInput';
import CustomSelect from '../../components/forms/CustomSelect';
import { Fields } from '../ecommerce/inventory/constants';
import { publicIpv4 } from 'public-ip';
import { getLocationData } from '../../redux/app/action';
import { createUser } from '../../redux/auth/action';
import { useNavigate } from 'react-router';
import customToast from '../../components/alert/CustomToast';
import { ToastContainer } from 'react-toastify';
// import { Twitter, Facebook, GitHub } from 'react-feather';

const Register = () => {
  const { t: translate } = useTranslation();
  const useFormObj = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { locationData } = useSelector((state) => state.app);
  const [togglePassword, setTogglePassword] = useState(false);
  const [isPolicyChecked, setIsPolicyChecked] = useState(false);

  useEffect(() => {
    getClientLocationData();
  }, []);

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };

  const getClientLocationData = async () => {
    const ip = await publicIpv4({
      fallbackUrls: ['https://ifconfig.co/ip']
    });

    dispatch(getLocationData(ip));
  };

  const handleRegistration = () => {
    if (!isPolicyChecked) {
      return customToast('Please accept our Privacy Policy', 'error');
    }
    const formData = useFormObj.watch();
    const { country, city } = locationData;

    const requestBody = {
      ...formData,
      city: city.name,
      countryOfOrigin: country.name,
      preferredLanguage: 'en'
    };

    dispatch(createUser(requestBody, () => navigate('/login/')));
  };

  return (
    <FormProvider {...useFormObj}>
      <Container fluid={true} className="p-0">
        <Row>
          <Col xs="12">
            <div className="login-card">
              <div>
                <div>
                  <a className="logo" href="#javascript">
                    <img className="img-fluid for-dark" src={require('../../assets/images/logo/login.png')} alt="looginpage" />
                    <img className="img-fluid for-light" src={require('../../assets/images/logo/logo_dark.png')} alt="looginpage" />
                  </a>
                </div>
                <div className="login-main">
                  <Form className="theme-form" onSubmit={(e) => useFormObj.handleSubmit(handleRegistration)(e)}>
                    <h4>{'Create your store'}</h4>
                    <p>{'All fields are required.'}</p>
                    <Row>
                      <Col xs="6">
                        <CustomInput name={'firstname'} label={translate(FirstName)} labelOptions={{ useTopLabel: true }} isRequired={true} placeholder="John" />
                      </Col>
                      <Col xs="6">
                        <CustomInput name={'lastname'} label={translate(LastName)} labelOptions={{ useTopLabel: true }} isRequired={true} placeholder="Doe" />
                      </Col>
                    </Row>
                    <CustomInput name={'storeName'} label={translate(StoreName)} labelOptions={{ useTopLabel: true }} isRequired={true} />
                    <CustomSelect
                      name={'sellerType'}
                      label={translate(StoreType)}
                      noOption={translate(Fields.Select)}
                      labelOptions={{ useTopLabel: true }}
                      menu={
                        <>
                          <option value={Fields.Merchant}>{translate(Fields.MerchantType1)}</option>
                          <option value={Fields.Merchant}>{translate(Fields.MerchantType2)}</option>
                          <option value={Fields.Vendor}>{translate(Fields.VendorType1)}</option>
                          <option value={Fields.Vendor}>{translate(Fields.VendorType2)}</option>
                        </>
                      }
                    />
                    <CustomInput name={'phoneNumber'} label={translate(PhoneNumber)} labelOptions={{ useTopLabel: true }} isRequired={true} />
                    <CustomInput name={'email'} label={translate(EmailAddress)} inputOptions={{ type: 'email' }} labelOptions={{ useTopLabel: true }} isRequired={true} />
                    <div className="mb-3 position-relative">
                      <CustomInput
                        name={'password'}
                        label={translate(Password)}
                        inputOptions={{ type: togglePassword ? 'text' : 'password' }}
                        labelOptions={{ useTopLabel: true }}
                        isRequired={true}
                        placeholder="*********"
                      />
                      <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}>
                        <span className={togglePassword ? '' : 'show'}></span>
                      </div>
                    </div>
                    <div className="login-btn mb-0">
                      <div className="checkbox ms-3">
                        <Input id="checkbox1" type="checkbox" onChange={(e) => setIsPolicyChecked(e.target.checked)} />
                        <Label className="text-muted" for="checkbox1">
                          {'Agree with'}
                          <a className="ms-2" href="#javascript">
                            {PrivacyPolicy}
                          </a>
                        </Label>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Button color="primary" type="submit">
                          {CreateAccount}
                        </Button>
                      </div>
                    </div>
                    <p className="mt-4 mb-0">
                      {'Already have an account?'}
                      <a className="ms-2" href="/login">
                        {SignIn}
                      </a>
                    </p>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ToastContainer autoClose={3000} />
    </FormProvider>
  );
};

export default Register;
