import { isNull, isNil } from 'lodash';
import { call, put } from 'redux-saga/effects';
import { setErrorResponse, setLoading, setSuccessResponse } from '../redux/app/action';
import { redirectToLogin, setIsAuthenticated, setLoginStatus } from '../redux/auth/action';

export function* requestHandler(api, stateSetter, successCallback = null, navigate = null, errorHandler = null) {
  yield put(setLoading(true));
  const { status, statusCode, data, error } = yield call(api);

  if (statusCode === 401) {
    yield put(setIsAuthenticated(false));
    yield put(setLoginStatus(false));
    yield put(redirectToLogin());
  } else {
    yield put(setIsAuthenticated(true));
    yield put(setLoginStatus(true));
  }

  if (statusCode >= 400 && statusCode <= 510 && !status) {
    yield put(setLoading(false));
    yield put(setErrorResponse({ error: error, isError: true, isSuccess: false }));
    yield successCallback(data);

    if (!isNull(errorHandler)) {
      yield errorHandler(error);
    }

    if (!isNull(navigate)) {
      navigate();
    }
  } else {
    yield put(setSuccessResponse({ success: data, isError: false, isSuccess: true }));
    yield put(setLoading(false));

    if (!isNull(successCallback)) {
      yield successCallback(data);
    }

    if (!isNil(stateSetter)) {
      yield put(stateSetter(data));
    }

    if (!isNull(navigate)) {
      yield navigate();
    }
  }
}
