import { SET_BRANDS } from '../actionTypes';

const initialState = {
  allBrands: []
};

const brandReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_BRANDS:
      return { ...state, allBrands: payload };
    default:
      return state;
  }
};

export default brandReducer;
