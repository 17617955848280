import { isEmpty, isUndefined } from 'lodash';

export const getStaticDataValue = (data, property) => {
  return !isEmpty(data) && !isUndefined(data[`${property}`]) ? data[`${property}`] : [];
};

export const processCustomEntryForCamera = (value) => {
  if (isEmpty(value)) return [];

  const valueType = typeof value[0];

  if (valueType === 'string') {
    return value.map((res) => res.toUpperCase());
  }

  if (valueType === 'object') {
    const data = value[0].label;
    const valueInNumber = parseInt(data);
    return [`${valueInNumber}MP`];
  }
};

export const processCustomEntryForProductDescription = (values) => {
  if (isEmpty(values)) {
    return [];
  }

  const data = values.map((val) => (typeof val === 'object' ? val.label : val));
  return data;
};
