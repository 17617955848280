import Swal from 'sweetalert2';

const confirmOptionsDefaults = {
  showConfirmButton: false,
  showDenyButton: false,
  confirmButtonText: 'Yes',
  denyButtonText: 'No'
};

export default function Alerts(title, text, icon, confirmOptions = confirmOptionsDefaults, cb = null, preConfirm = null) {
  Swal.fire({
    title,
    text,
    icon,
    showConfirmButton: confirmOptions.showConfirmButton,
    confirmButtonText: confirmOptions.confirmButtonText,
    showDenyButton: confirmOptions.showDenyButton,
    denyButtonText: confirmOptions.denyButtonText,
    reverseButtons: true,
    preConfirm: preConfirm
  }).then(cb);
}
