import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import _ from 'lodash';
import Breadcrumb from '../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Media } from 'reactstrap';
import imageExample from '../../../assets/images/ecommerce/banner.jpg';
import { useTranslation } from 'react-i18next';
import { getBrandDetails } from '../../../redux/brands/action';

export default function BrandDetail() {
  const { t: translate } = useTranslation();
  const { bId } = useParams();
  const dispatch = useDispatch();
  const [currentBrand, setCurrentBrand] = useState({});
  const brandId = Number(bId);

  useEffect(() => {
    if (_.isEmpty(currentBrand)) {
      dispatch(getBrandDetails(brandId, (data) => setCurrentBrand(data)));
    }
  }, []);

  const DescriptionAndImage = ({ show = false }) => {
    return show ? (
      <>
        <hr />
        <Row>
          <Media body className="img-100" src={imageExample} />
          <Media body className="img-100" src={imageExample} />
          <Media body className="img-100" src={imageExample} />
        </Row>
      </>
    ) : null;
  };
  // { type: isError ? 'error' : 'success' });

  return (
    <Fragment>
      <Breadcrumb parent="brands" title={`${currentBrand.brandName}`} />
      <Container fluid={true}>
        <Row>
          <Col sm="6">
            {/* <Card>
              <DescriptionAndImage data={currentBrand} show={true} />
            </Card>
            <hr /> */}
            <Card>
              <CardBody>
                <h5>{`${translate('Images')} for ${currentBrand.brandName}`} </h5>
                <DescriptionAndImage show={!_.isEmpty(currentBrand)} />
              </CardBody>
            </Card>
          </Col>
          <Col sm="6">
            <Card>
              {!_.isEmpty(currentBrand) && (
                <CardBody>
                  <h5>{`${currentBrand.brandName} - ${translate('Available Regions')}`} </h5>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
