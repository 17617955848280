export default {
  lang: 'es',
  General: 'General',
  Dashboards: 'Cuadros de mando',
  Widgets: ' widgets',
  Dashboard: 'Tablero',
  Default: 'Defecto',
  Ecommerce: 'Comercio electrónico',
  Chart: 'Gráfico',
  Applications: 'Aplicaciones',
  ReadytouseApps: 'Gereed om programme te gebruik',
  Home: 'Hogar',
  Store: 'Tienda'
};
