import { REQUEST_ENDED, REQUEST_ERROR, REQUEST_STARTED } from '../actionTypes';

export const requestStarted = (payload) => ({
  type: REQUEST_STARTED,
  payload
});

export const requestFinished = (payload) => ({
  type: REQUEST_ENDED,
  payload
});

export const requestError = (payload) => ({
  type: REQUEST_ERROR,
  payload
});
