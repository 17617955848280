import React, { useContext } from 'react';
import { ValidRightComponents } from '../constants';
import ElectronicsAttributes from './attributes-layout/ElectronicsAttributes';
import FashionAttributes from './attributes-layout/FashionAttributes';
import { AddProductContext } from '../../../../helper/contextHelper';
import { Col } from 'reactstrap';

const ComponentSelector = ({ name }) => {
  switch (name) {
    case ValidRightComponents.Electronics:
      return <ElectronicsAttributes />;
    case ValidRightComponents.Fashion:
      return <FashionAttributes />;
    default:
      return null;
  }
};

export default function Right() {
  const { categoryName, showRightComponent } = useContext(AddProductContext);

  return (
    <Col style={{ display: showRightComponent ? 'block' : 'none' }}>
      <ComponentSelector name={categoryName} />
    </Col>
  );
}
