import React, { useContext, useEffect } from 'react';
import { FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardHeader } from 'reactstrap';
import CustomTypeHead from '../../../../../components/ecommerce/customTypeHead';
import { getStaticDataValue, processCustomEntryForCamera } from '../../../../../helper';
import { Attributes, Fields } from '../../constants';
import { useFormContext } from 'react-hook-form';
import CustomInput from '../../../../../components/forms/CustomInput';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import { isNil } from 'lodash';
import { AddProductContext } from '../../../../../helper/contextHelper';

export default function ElectronicsAttributes() {
  const isEditMode = useContext(AddProductContext);
  const { setValue, watch, getValues } = useFormContext();
  const { t: translate } = useTranslation();
  const { staticData } = useSelector((state) => state.product);
  const sizesTypes = getStaticDataValue(staticData, 'Other Size').map(({ value }) => value);
  const storageSizes = getStaticDataValue(staticData, 'ROM').map(({ value }) => value);
  const weightTypes = getStaticDataValue(staticData, 'Weight').map(({ value }) => value.toUpperCase());
  const RAMTypes = getStaticDataValue(staticData, 'RAM').map(({ value }) => value);
  const processorTypes = getStaticDataValue(staticData, 'Processor').map(({ value }) => value);
  const primaryCameraTypes = getStaticDataValue(staticData, 'Primary Camera').map(({ value }) => `${value}MP`);
  const secondaryCameraTypes = getStaticDataValue(staticData, 'Secondary Camera').map(({ value }) => `${value}MP`);
  const displayTypes = getStaticDataValue(staticData, 'Display Type')
    .map(({ value }) => value)
    .sort();
  const bluetoothTypes = getStaticDataValue(staticData, 'Bluetooth').map(({ value }) => value);
  const wifiTypes = getStaticDataValue(staticData, 'WiFi').map(({ value }) => `Wifi ${value}`);
  const networkTypes = bluetoothTypes.concat(wifiTypes);
  const attributesFromDB = getValues('attributesFromDB');

  useEffect(() => {
    if (isEditMode && !isNil(attributesFromDB)) {
      const attributeObject = Object.create(null);
      attributesFromDB.forEach(({ key, value, dataType }) => {
        let output = value;
        if (dataType === 'array') {
          output = value.split(',');
        }

        attributeObject[key] = output;
      });

      setValue('attribute', attributeObject);
    }
  }, [attributesFromDB]);

  const handleCameraOnChange = (attribute, value) => {
    const upperValue = processCustomEntryForCamera(value);
    setValue(attribute, upperValue);
  };

  return (
    <Card>
      <CardHeader>
        <h5>{translate(Attributes)}</h5>
      </CardHeader>
      <CardBody>
        <CustomSelect
          floating={true}
          label={translate('Size')}
          inputStyles={{ className: 'd-flex col' }}
          name={'attribute.size'}
          noOption={translate(Fields.Select)}
          menu={sizesTypes.map((size, index) => (
            <option key={index} value={size}>
              {translate(size)}
            </option>
          ))}
        />

        <CustomInput name={'attribute.width'} label={translate(Fields.Width)} floating={true} inputStyles={{ className: 'd-flex col-md-12' }} inputOptions={{ type: 'number' }} />
        <CustomInput name={'attribute.height'} label={translate(Fields.Height)} floating={true} inputStyles={{ className: 'd-flex col-md-12' }} inputOptions={{ type: 'number' }} />
        <CustomInput name={'attribute.length'} label={translate(Fields.Length)} floating={true} inputStyles={{ className: 'd-flex col-md-12' }} inputOptions={{ type: 'number' }} />

        {/** For Phones, Computers, Devices with Screen */}
        <CustomSelect
          floating={true}
          label={translate(Fields.StorageTypes)}
          inputStyles={{ className: 'd-flex col' }}
          name={'attribute.storageType'}
          noOption={translate(Fields.Select)}
          menu={
            <>
              <option value={translate(Fields.HDD)}>{translate(Fields.HDD)}</option>
              <option value={translate(Fields.SSD)}>{translate(Fields.SSD)}</option>
              <option value={translate(Fields.ROM)}>{translate(Fields.ROM)}</option>
            </>
          }
        />

        <CustomSelect
          floating={true}
          label={translate(Fields.StorageSizes)}
          inputStyles={{ className: 'd-flex col' }}
          name={'attribute.storageSize'}
          noOption={translate(Fields.Select)}
          menu={storageSizes.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        />

        <FormLabel className="col-form-label">{translate(Fields.Weight)}</FormLabel>
        <div className="row row-cols-sm-3">
          <CustomSelect
            inputStyles={{ className: 'd-flex col-md-6' }}
            name={'attribute.weightType'}
            noOption={translate(Fields.Select)}
            menu={weightTypes.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          />
          <CustomInput
            name={'attribute.weightValue'}
            placeholder={`${translate(Fields.Weight)}`.toLowerCase()}
            inputStyles={{ className: 'd-flex col-md-6' }}
            inputOptions={{ type: 'number' }}
          />
        </div>

        <CustomTypeHead
          id={translate(Fields.Processor)}
          data={processorTypes}
          label={translate(Fields.Processor)}
          name={'attribute.processor'}
          placeholder={translate(Fields.Processor)}
          typeHeadOptions={{ multiple: false }}
          selected={watch('attribute.processor')}
          isRequired={true}
          onChangeSelection={(value) => setValue('attribute.processor', value)}
        />

        <CustomSelect
          floating={true}
          label={translate(Fields.RAM)}
          inputStyles={{ className: 'd-flex col-md-12' }}
          name={'attribute.ram'}
          noOption={translate(Fields.SelectARam)}
          menu={RAMTypes.map((value, index) => (
            <option key={index} value={value}>
              {value}
            </option>
          ))}
        />

        <CustomTypeHead
          id={translate(Fields.PrimaryCamera)}
          data={primaryCameraTypes}
          label={translate(Fields.PrimaryCamera)}
          name={'attribute.primaryCamera'}
          placeholder={`${translate(Fields.Select)} ${translate(Fields.or)} ${translate(Fields.AddNewItem)}`}
          typeHeadOptions={{ ...{ multiple: false, allowNew: true } }}
          selected={watch('attribute.primaryCamera')}
          onChangeSelection={(value) => handleCameraOnChange('attribute.primaryCamera', value)}
        />

        <CustomTypeHead
          id={translate(Fields.SecondaryCamera)}
          data={secondaryCameraTypes}
          label={translate(Fields.SecondaryCamera)}
          name={'attribute.secondaryCamera'}
          placeholder={`${translate(Fields.Select)} ${translate(Fields.or)} ${translate(Fields.AddNewItem)}`}
          typeHeadOptions={{ ...{ multiple: false, allowNew: true } }}
          selected={watch('attribute.secondaryCamera')}
          onChangeSelection={(value) => handleCameraOnChange('attribute.secondaryCamera', value)}
        />

        <CustomTypeHead
          id={translate(Fields.DisplayType)}
          data={displayTypes}
          label={translate(Fields.DisplayType)}
          name={'attribute.displayType'}
          placeholder={translate(Fields.Select)}
          typeHeadOptions={{ multiple: false }}
          selected={watch('attribute.displayType')}
          onChangeSelection={(value) => setValue('attribute.displayType', value)}
        />

        <CustomInput name={'attribute.resolution'} label={translate(Fields.Resolution)} floating={true} placeholder="3280x1080" />
        <CustomInput name={'attribute.screenSize'} label={translate(Fields.ScreenSize)} floating={true} inputOptions={{ type: 'number' }} />
        <CustomInput name={'attribute.battery'} label={translate(Fields.Battery)} floating={true} />

        <CustomTypeHead
          id={translate(Fields.Network)}
          data={networkTypes}
          label={translate(Fields.Network)}
          name={'attribute.networks'}
          placeholder={translate(Fields.Select)}
          typeHeadOptions={{ multiple: true }}
          selected={watch('attribute.networks')}
          onChangeSelection={(value) => setValue('attribute.networks', value)}
        />
      </CardBody>
    </Card>
  );
}
