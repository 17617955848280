import { takeLatest } from 'redux-saga/effects';
import { getBrands, setBrands } from './action';
import customToast from '../../components/alert/CustomToast';
import { DELETED_SUCCESSFULLY, SAVED_SUCCESSFULLY, UPDATED_SUCCESSFULLY } from '../../constant';
import { requestHandler } from '../../services/handler';
import { deleteApi, getApi, postApi, putApi } from '../../services';
import { DELETE_BRANDS, GET_BRANDS, GET_BRANDS_DETAILS, SAVE_BRANDS, UPDATE_BRAND } from '../actionTypes';

const INVENTORY_URL = process.env.REACT_APP_INVENTORY_URL;

function* saveBrands(action) {
  const { payload } = action;

  const cb = () => {
    customToast(`${payload.brandName} ${SAVED_SUCCESSFULLY}`, 'success');
    return getBrands();
  };

  const handleError = (err) => {
    const { errors } = err;
    return errors.map((data) => customToast(data.message, 'error'));
  };

  yield requestHandler(postApi.bind(this, `${INVENTORY_URL}/brands`, payload), cb, null, null, handleError);
}

function* updateBrand(action) {
  const {
    payload: { id, body }
  } = action;
  const url = `${INVENTORY_URL}/brands/${id}`;

  const onSuccess = () => {
    customToast(UPDATED_SUCCESSFULLY, 'success');
    return getBrands();
  };

  const onError = (err) => {
    const { errors } = err;
    return errors.map((message) => customToast(message, 'error'));
  };

  yield requestHandler(putApi.bind(this, url, body), onSuccess, null, null, onError);
}

function* fetchBrands() {
  yield requestHandler(getApi.bind(this, `${INVENTORY_URL}/brands`), setBrands);
}

function* fetchBrandsDetails(action) {
  const { payload: id, callback } = action;
  yield requestHandler(getApi.bind(this, `${INVENTORY_URL}/brands/${id}`), null, callback);
}

function* deleteBrand(action) {
  const { payload: id } = action;

  const onSuccess = () => {
    customToast(DELETED_SUCCESSFULLY, 'success');
    return getBrands();
  };

  const onError = (err) => {
    const { errors } = err;
    return errors.map((message) => customToast(message, 'error'));
  };

  yield requestHandler(deleteApi.bind(this, `${INVENTORY_URL}/brands/${id}`), onSuccess, null, null, onError);
}

export function* WatcherBrandsApp() {
  yield takeLatest(SAVE_BRANDS, saveBrands);
  yield takeLatest(UPDATE_BRAND, updateBrand);
  yield takeLatest(GET_BRANDS, fetchBrands);
  yield takeLatest(GET_BRANDS_DETAILS, fetchBrandsDetails);
  yield takeLatest(DELETE_BRANDS, deleteBrand);
}
