import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, FormGroup, Label, Input } from 'reactstrap';
import { Category, ProductName } from '../../../../../constant';
import { Brand, Minicategory, ProductModel, Quantity, Subcategory } from '../../constants';

export default function FashionAttributes() {
  const { t: translate } = useTranslation();
  return (
    <Card>
      <CardBody>
        <FormGroup>
          <Label className="col-form-label">{translate(ProductName)}</Label>
          <Input className="form-control" type="text" placeholder="Product Name" />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">{translate(ProductModel)}</Label>
          <Input className="form-control" type="text" placeholder="Product Model" />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">{translate(Brand)}</Label>
          <Input type="select" name="select" className="form-control">
            <option>{'Select a Brand'}</option>
            <option defaultValue="1">{'One'}</option>
            <option defaultValue="2">{'Two'}</option>
            <option defaultValue="3">{'Three'}</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">{translate(Category)}</Label>
          <Input type="select" name="select" className="form-control">
            <option>{'Select a Category'}</option>
            <option defaultValue="1">{'One'}</option>
            <option defaultValue="2">{'Two'}</option>
            <option defaultValue="3">{'Three'}</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">{translate(Subcategory)}</Label>
          <Input type="select" name="select" className="form-control">
            <option>{'Select a Subcategory'}</option>
            <option defaultValue="1">{'One'}</option>
            <option defaultValue="2">{'Two'}</option>
            <option defaultValue="3">{'Three'}</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">{translate(Minicategory)}</Label>
          <Input type="select" name="select" className="form-control">
            <option>{'Select a Minicategory'}</option>
            <option defaultValue="1">{'One'}</option>
            <option defaultValue="2">{'Two'}</option>
            <option defaultValue="3">{'Three'}</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">{translate(Quantity)}</Label>
          <Input className="form-control" type="text" placeholder="Category Name" />
        </FormGroup>
        <FormGroup>
          <Label className="col-form-label">{translate(ProductName)}</Label>
          <Input className="form-control" type="text" placeholder="Category Name" />
        </FormGroup>
      </CardBody>
    </Card>
  );
}
