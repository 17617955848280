import axios from 'axios';

function response(status, statusCode, data, error, message) {
  return {
    status,
    statusCode,
    data,
    error,
    message
  };
}

function handleError(error) {
  return { message: error.message || '', response: error.response || {} };
}

axios.interceptors.request.use(
  (config) => {
    config.headers.common['Content-Type'] = 'application/json';
    config.headers.common['Accept'] = 'application/json';
    config.headers.common['x-store-ref'] = window['store-ref'];
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response.status === 401) {
      window.location = '/login';
    }

    return Promise.reject(error);
  }
);

export const postApi = async (url, body) => {
  try {
    const res = await axios.post(url, body, {
      withCredentials: true
    });
    return response(true, res.status, res.data, null);
  } catch (error) {
    const err = handleError(error);
    return response(false, err.response?.status, null, err.response?.data, err.message);
  }
};

export const getApi = async (url) => {
  try {
    const res = await axios.get(url, {
      withCredentials: true
    });
    return response(true, res.status, res.data, null);
  } catch (error) {
    const err = handleError(error);
    return response(false, err.response?.status, null, err.response?.data, err.message);
  }
};

export const putApi = async (url, body) => {
  try {
    const res = await axios.put(url, body, {
      withCredentials: true
    });
    return response(true, res.status, res.data, null);
  } catch (error) {
    const err = handleError(error);
    return response(false, err.response?.status, null, err.response?.data, err.message);
  }
};

export const patchApi = async (url, body) => {
  try {
    const res = await axios.patch(url, body, {
      withCredentials: true
    });
    return response(true, res.status, res.data, null);
  } catch (error) {
    const err = handleError(error);
    return response(false, err.response?.status, null, err.response?.data, err.message);
  }
};

export const deleteApi = async (url) => {
  try {
    const res = await axios.delete(url, {
      withCredentials: true
    });
    return response(true, res.status, res.data, null);
  } catch (error) {
    const err = handleError(error);
    return response(false, err.response?.status, null, err.response?.data, err.message);
  }
};
