export default {
  lang: 'cn',
  General: '一般',
  Dashboards: '小部件',
  Widgets: '小部件',
  Dashboard: '仪表板',
  Default: '默认',
  Ecommerce: '电子商务',
  Chart: '图表',
  Applications: '应用领域',
  ReadytouseApps: '准备使用的应用程序'
};
