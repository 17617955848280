import { GET_SELLER_INFO, SET_SELLER_INFO, UPDATE_SELLER_INFO } from '../actionTypes';

export const getSellerInfo = (userId) => ({
  type: GET_SELLER_INFO,
  payload: { userId }
});

export const setSellerInfo = (payload) => ({
  type: SET_SELLER_INFO,
  payload
});

export const updateSellerInfo = (userId, body, callback = () => {}) => ({
  type: UPDATE_SELLER_INFO,
  payload: {
    userId,
    body
  },
  callback
});
