import React from 'react';
import { FormGroup } from 'reactstrap';
import DatePicker from 'react-datepicker';
import { useFormContext } from 'react-hook-form';
import { isNil } from 'lodash';
import { useTranslation } from 'react-i18next';

export default function CustomDatePicker({
  label,
  name,
  selected,
  minDate = new Date(),
  dateFormat = 'yyyy-MM-dd',
  placeholder = '',
  isRequired = false,
  fieldOptions = {},
  onInputChange = null,
  inputStyles = { className: '', sx: {} }
}) {
  const { t: translate } = useTranslation();
  const {
    register,
    formState: { errors }
  } = useFormContext();
  const { onChange } = register(name, { required: { value: isRequired, message: `${label} is required` }, ...fieldOptions });
  return (
    <FormGroup className={inputStyles.className} sx={inputStyles.sx}>
      <DatePicker
        className="form-control digits"
        selected={selected}
        onChange={(value) => (!isNil(onInputChange) ? onInputChange(value) : onChange(value))}
        minDate={minDate}
        dateFormat={dateFormat}
        placeholderText={placeholder}
      />
      {errors[`${name}`] && <span className={'text-danger'}>{`${translate(errors[`${name}`]?.message)}`}</span>}
    </FormGroup>
  );
}
