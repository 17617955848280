import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, FormGroup, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Fields, discountDateError, discountValueError } from '../../constants';
import { Add } from '../../../../../constant';
import { isEmpty, isNil } from 'lodash';
import formatDate, { validateEndDateIsGreater } from '../../../../../helper/formatDate';
import CustomDatePicker from '../../../../../components/forms/CustomDatePicker';
import CustomInput from '../../../../../components/forms/CustomInput';
import { useFormContext } from 'react-hook-form';
import { AddProductContext } from '../../../../../helper/contextHelper';

export default function Discounts() {
  const isEditMode = useContext(AddProductContext);
  const { setValue, register, setError, clearErrors, getValues } = useFormContext();
  register('discountData');
  const { t: translate } = useTranslation();
  const [disableBtn, setDisableBtn] = useState(true);
  const [discountData, setDiscountData] = useState([]);
  const [discountStartDate, setDiscountStartDate] = useState('');
  const [discountEndDate, setDiscountEndDate] = useState('');
  const [discountValue, setDiscountValue] = useState(0);
  const discountFromDB = getValues('discountFromDB');

  useEffect(() => {
    if (isEditMode && !isNil(discountFromDB)) {
      const { discountValue, startDate, endDate } = discountFromDB;
      setDiscountData([{ discountValue, startDate: new Date(startDate).toUTCString(), endDate: new Date(endDate).toUTCString() }]);
      setValue('discountData', { discountValue: parseInt(discountValue), start: new Date(startDate).toISOString(), end: new Date(endDate).toISOString() });
    }
  }, [discountFromDB]);

  useEffect(() => {
    const start = discountStartDate ? new Date(discountStartDate).toISOString() : '';
    const end = discountEndDate ? new Date(discountEndDate).toISOString() : '';
    const discount = !isEmpty(discountValue) && discountValue <= 45;
    const disabled = discount && !isEmpty(start) && !isEmpty(end);
    setDisableBtn(!disabled);
  }, [discountValue, discountStartDate, discountEndDate]);

  const validateDiscountValue = (value) => {
    if (value > 45) {
      setError('discountValue', { type: 'custom', message: translate(discountValueError) });
      return true;
    }

    return false;
  };

  const handleDiscount = () => {
    clearErrors('startDate');
    clearErrors('discountValue');

    const startDate = new Date(discountStartDate).toUTCString();
    const endDate = new Date(discountEndDate).toUTCString();

    const formattedEndDate = formatDate(discountEndDate);
    const formattedStartDate = formatDate(discountStartDate);

    if (!validateEndDateIsGreater(formattedEndDate, formattedStartDate)) {
      return setError('startDate', { type: 'custom', message: translate(discountDateError) });
    }

    setDiscountData([{ discountValue, startDate, endDate }]);
    setDiscountValue(0);
    setDiscountStartDate('');
    setDiscountEndDate('');
    setValue('discountData', { discountValue: parseInt(discountValue), start: new Date(discountStartDate).toISOString(), end: new Date(discountEndDate).toISOString() });
  };

  const handleRemoveDiscount = () => {
    setDiscountData([]);
    setValue('discountData', {});
  };

  const DiscountBox = ({ discountValue = '', startDate = '', endDate = '', onClick = null }) => (
    <div className="shadow-sm shadow-showcase p-10 mx-1 my-1 b-primary rounded">
      <div className="d-flex justify-content-center">
        <h1>{`${discountValue}%`}</h1>
      </div>
      <div className="d-flex justify-content-between">
        <div className="d-flex flex-column">
          <b>Start Date</b>
          <small>{formatDate(startDate)}</small>
        </div>
        <div className="mt-auto" onClick={onClick}>
          <i style={{ cursor: 'pointer' }} className="fa fa-trash-o font-primary"></i>
        </div>
        <div className="d-flex flex-column">
          <b>End Date</b>
          <small>{formatDate(endDate)}</small>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <Card>
        <CardBody>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h6>Discount</h6>
          </div>
          <hr />
          {discountData.map(({ discountValue, startDate, endDate }, index) => (
            <DiscountBox key={index} discountValue={discountValue} startDate={startDate} endDate={endDate} onClick={handleRemoveDiscount} />
          ))}

          <hr />
          <div className="row row-cols-sm-3 theme-form mt-3 billing-form">
            <CustomDatePicker
              label={'Start Date'}
              name={'startDate'}
              selected={discountStartDate}
              onInputChange={(value) => setDiscountStartDate(value)}
              placeholder={translate(Fields.StartDate)}
            />
            <CustomDatePicker
              label={'End Date'}
              name={'endDate'}
              selected={discountEndDate}
              onInputChange={(value) => setDiscountEndDate(value)}
              placeholder={translate(Fields.EndDate)}
            />
            <CustomInput
              inputStyles={{ className: 'col-md-12' }}
              name="discountValue"
              inputOptions={{ type: 'number' }}
              value={discountValue}
              placeholder="Discount %"
              onInputChange={(e) => {
                setDiscountValue(e.target.value);
                validateDiscountValue(e.target.value);
              }}
              setInputError={{ hasError: discountValue > 45 }}
            />
            <FormGroup className="d-flex">
              <Button disabled={disableBtn} onClick={handleDiscount} color="primary">
                {Add}
              </Button>
            </FormGroup>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
