import { takeLatest } from 'redux-saga/effects';
import customToast from '../../components/alert/CustomToast';
import { deleteApi, postApi } from '../../services';
import { requestHandler } from '../../services/handler';
import { DELETE_FILE, UPLOAD_FILE } from '../actionTypes';

const INVENTORY_URL = process.env.REACT_APP_INVENTORY_URL;

function* uploadFileHandler(action) {
  const { payload, callback } = action;

  const onSuccess = (data) => {
    customToast('Upload successful', 'success');
    return callback(data, null);
  };

  const onError = (err) => {
    return callback(null, err.errors);
  };

  yield requestHandler(postApi.bind(this, `${INVENTORY_URL}/file/upload`, payload), null, onSuccess, null, onError);
}

function* deleteFileHandler(action) {
  const { payload: fileId } = action;

  const onSuccess = () => {
    return customToast('File deleted successful', 'success');
  };

  const onError = (data) => {
    data.errors.map((message) => customToast(`${message}`, 'error'));
  };

  yield requestHandler(deleteApi.bind(this, `${INVENTORY_URL}/file/delete/${fileId}`), null, onSuccess, null, onError);
}

export function* WatcherFileUploadApp() {
  yield takeLatest(UPLOAD_FILE, uploadFileHandler);
  yield takeLatest(DELETE_FILE, deleteFileHandler);
}
