import {
  CONVERT_GROUP_CURRENCIES,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_MARKETPLACE_PRODUCTS,
  GET_MARKETPLACE_PRODUCTS_OPTIONS,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_IDENTIFIER_NUMBER,
  GET_PRODUCT_STATIC_DATA,
  RESET_PRODUCT_LIST,
  SET_CURRENT_SELECTED_PRODUCT,
  SET_PRODUCTS,
  SET_PRODUCT_STATIC_DATA,
  UPDATE_PRODUCTS
} from '../actionTypes';

export const getProductIdentifier = (type, callback) => ({
  type: GET_PRODUCT_IDENTIFIER_NUMBER,
  payload: { type },
  callback
});

export const getProductStaticData = () => ({
  type: GET_PRODUCT_STATIC_DATA
});

export const setProductStaticData = (payload) => ({
  type: SET_PRODUCT_STATIC_DATA,
  payload
});

export const convertGroupCurrencies = (payload, callback) => ({
  type: CONVERT_GROUP_CURRENCIES,
  payload,
  callback
});

export const createProduct = (payload, successCallBack = null, errorHandler = null) => ({
  type: CREATE_PRODUCT,
  payload,
  successCallBack,
  errorHandler
});

export const updateProduct = (id, body, successCallBack = null, errorHandler = null) => ({
  type: UPDATE_PRODUCTS,
  payload: { id, body },
  successCallBack,
  errorHandler
});

export const getProducts = (options) => ({ type: GET_PRODUCTS, payload: options });

export const fetchMarketplaceProducts = (options, callback = () => {}) => ({
  type: GET_MARKETPLACE_PRODUCTS,
  payload: options,
  callback
});

export const fetchMarketplaceProductOptions = (callback = () => {}) => ({ type: GET_MARKETPLACE_PRODUCTS_OPTIONS, callback });

export const getProductById = (productId) => ({ type: GET_PRODUCT_BY_ID, payload: productId });

export const deleteProduct = (productId) => ({ type: DELETE_PRODUCT, payload: productId });

export const setProducts = (payload) => ({ type: SET_PRODUCTS, payload });

export const setCurrentSelectedProduct = (payload) => ({ type: SET_CURRENT_SELECTED_PRODUCT, payload });

export const resetProductList = () => ({ type: RESET_PRODUCT_LIST });
