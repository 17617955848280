import { DELETE_FILE, UPLOAD_FILE } from '../actionTypes';

export const uploadFile = (payload, callback) => ({
  type: UPLOAD_FILE,
  payload,
  callback
});

export const deleteFile = (payload) => ({
  type: DELETE_FILE,
  payload
});
