import { put, takeLatest } from 'redux-saga/effects';
import { deleteApi, getApi, postApi, putApi } from '../../services';
import { requestHandler } from '../../services/handler';
import {
  CONVERT_GROUP_CURRENCIES,
  CREATE_PRODUCT,
  DELETE_PRODUCT,
  GET_MARKETPLACE_PRODUCTS,
  GET_MARKETPLACE_PRODUCTS_OPTIONS,
  GET_PRODUCTS,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_IDENTIFIER_NUMBER,
  GET_PRODUCT_STATIC_DATA,
  UPDATE_PRODUCTS
} from '../actionTypes';
import { getProducts, setCurrentSelectedProduct, setProductStaticData, setProducts } from './action';
import customToast from '../../components/alert/CustomToast';
import { DELETED_SUCCESSFULLY, SAVED_SUCCESSFULLY, UPDATED_SUCCESSFULLY } from '../../constant';
import { isEmpty, isNull, isUndefined } from 'lodash';

const INVENTORY_URL = process.env.REACT_APP_INVENTORY_URL;
const LOCATION_URL = process.env.REACT_APP_LOCATION_URL;

function getProductListDetails() {
  const key = 'productTableOption';
  const items = localStorage.getItem(key);

  if (isNull(items)) {
    return {
      pageNumber: 1,
      itemNumber: 10
    };
  }

  return JSON.parse(items);
}

function* fetchProductIdentifierNumber(action) {
  const { payload, callback } = action;
  const { type } = payload;
  const url = `${INVENTORY_URL}/product/pin?type=${type}`;
  yield requestHandler(getApi.bind(this, url), null, callback);
}

function* fetchProductStaticData() {
  const url = `${INVENTORY_URL}/product/static-attributes/fields`;
  yield requestHandler(getApi.bind(this, url), setProductStaticData);
}

function* convertGroupCurrencies(action) {
  const { payload, callback } = action;
  const url = `${LOCATION_URL}/currency/group.convert`;
  yield requestHandler(postApi.bind(this, url, payload), null, callback);
}

function* createProduct(action) {
  const { payload, successCallBack, errorHandler } = action;
  const url = `${INVENTORY_URL}/product/create`;

  const onSuccess = () => {
    customToast(`Product ${SAVED_SUCCESSFULLY}`, 'success');
    const options = getProductListDetails();
    return put(getProducts(options));
  };

  const onError = (err) => {
    const { errors } = err;
    errors.map(({ message }) => customToast(message.join(', '), 'error'));
    return errorHandler(errors);
  };

  yield requestHandler(postApi.bind(this, url, payload), null, onSuccess, successCallBack, onError);
}

function* updateProduct(action) {
  const {
    payload: { id, body },
    successCallBack,
    errorHandler
  } = action;
  const url = `${INVENTORY_URL}/product/update/${id}`;

  const onSuccess = () => {
    customToast(`Product ${UPDATED_SUCCESSFULLY}`, 'success');
    const options = getProductListDetails();
    return put(getProducts(options));
  };

  const onError = (err) => {
    const { errors } = err;
    if (!isUndefined(errors)) {
      errors.map(({ message }) => customToast(message.join(', '), 'error'));
    }
    return errorHandler(errors);
  };

  yield requestHandler(putApi.bind(this, url, body), null, onSuccess, successCallBack, onError);
}

function* getMarketplaceProducts(actions) {
  const {
    payload: { page, size, sortColumn, sortDirection, search = '' },
    callback
  } = actions;
  let url = `${INVENTORY_URL}/product/marketplace?page=${page}&size=${size}`;

  if (sortColumn && sortDirection) {
    url = `${url}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;
  }

  if (!isEmpty(search)) {
    url = `${url}&search=${search}`;
  }

  yield requestHandler(getApi.bind(this, url), null, callback);
}

function* getMarketplaceProductOptions(actions) {
  const { callback } = actions;
  const url = `${INVENTORY_URL}/product/marketplace/options`;
  yield requestHandler(getApi.bind(this, url), null, callback);
}

function* getProductList(actions) {
  const {
    payload: { pageNumber, itemNumber, sortColumn = 'productId', sortDirection = 'desc', searchValue = '' }
  } = actions;
  let queryParams = `page=${pageNumber}&size=${itemNumber}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;

  if (!isEmpty(searchValue)) {
    queryParams = `${queryParams}&search=${searchValue}`;
  }

  const url = `${INVENTORY_URL}/product/list?${queryParams}`;
  yield requestHandler(getApi.bind(this, url), setProducts);
}

function* getOneProduct(action) {
  const { payload: productId } = action;
  const url = `${INVENTORY_URL}/product/list/${productId}`;

  yield requestHandler(getApi.bind(this, url), setCurrentSelectedProduct);
}

function* deleteProduct(action) {
  const { payload: productId } = action;
  const url = `${INVENTORY_URL}/product/delete/${productId}`;

  const onSuccess = () => {
    customToast(`Product ${DELETED_SUCCESSFULLY}`, 'success');
    const options = getProductListDetails();
    return put(getProducts(options));
  };

  const onError = (err) => {
    const { errors } = err;
    if (!isUndefined(errors)) {
      errors.map(({ message }) => customToast(message.join(', '), 'error'));
    }
    return;
  };

  yield requestHandler(deleteApi.bind(this, url), null, onSuccess, null, onError);
}

export function* WatchProductApp() {
  yield takeLatest(GET_PRODUCT_IDENTIFIER_NUMBER, fetchProductIdentifierNumber);
  yield takeLatest(GET_PRODUCT_STATIC_DATA, fetchProductStaticData);
  yield takeLatest(CONVERT_GROUP_CURRENCIES, convertGroupCurrencies);
  yield takeLatest(CREATE_PRODUCT, createProduct);
  yield takeLatest(UPDATE_PRODUCTS, updateProduct);
  yield takeLatest(GET_MARKETPLACE_PRODUCTS, getMarketplaceProducts);
  yield takeLatest(GET_MARKETPLACE_PRODUCTS_OPTIONS, getMarketplaceProductOptions);
  yield takeLatest(GET_PRODUCTS, getProductList);
  yield takeLatest(DELETE_PRODUCT, deleteProduct);
  yield takeLatest(GET_PRODUCT_BY_ID, getOneProduct);
}
