import { isEmpty, isNil, isNull } from 'lodash';
import React from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormGroup, Label } from 'reactstrap';
import CustomToken from '../CustomToken';

const CustomTypeHead = React.forwardRef(function CustomTypeHeadRef(
  {
    id,
    name,
    data,
    label,
    onChangeSelection = null,
    selected = [],
    onInputChange = () => {},
    placeholder = '',
    isRequired = false,
    inputStyles = { className: '', sx: {} },
    labelOptions = { useLabel: true },
    typeHeadOptions = { maxResults: 200, paginate: true, defaultSelected: [], allowNew: false, multiple: true, hideRemoveBtn: false, onRemoveChange: () => {} }
  },
  // eslint-disable-next-line no-unused-vars
  ref
) {
  const { t: translate } = useTranslation();
  const {
    register,
    formState: { errors, touchedFields },
    watch,
    getValues
  } = useFormContext();

  const fieldHasError = touchedFields[`${name}`] && isRequired && isEmpty(watch(name));
  const { onChange } = register(name);
  const val = getValues(name);
  let values = !isNil(val) ? val : [];
  const selectedValue = isEmpty(selected) ? values : selected;

  return (
    <>
      <FormGroup className={inputStyles.className} style={inputStyles.sx}>
        {labelOptions.useLabel ? <Label className="col-form-label">{translate(label)}</Label> : null}
        <Typeahead
          maxResults={typeHeadOptions.maxResults}
          paginate={typeHeadOptions.paginate}
          defaultSelected={typeHeadOptions.defaultSelected}
          id={id}
          allowNew={typeHeadOptions.allowNew}
          multiple={typeHeadOptions.multiple}
          onChange={isNull(onChangeSelection) ? onChange : onChangeSelection}
          options={data || []}
          selected={selectedValue}
          placeholder={placeholder}
          onInputChange={(e) => onInputChange(e)}
          renderToken={(option, { onRemove }, index) => (
            <CustomToken
              key={index}
              onRemove={(opt) => {
                onRemove(opt);
                !isNil(typeHeadOptions.onRemoveChange) && typeHeadOptions.onRemoveChange(opt);
              }}
              option={option}
              index={index}
              hideRemoveBtn={typeHeadOptions.hideRemoveBtn}
              processOptions={(option) => (typeof option === 'object' ? option.Label : option)}
            />
          )}
          isInvalid={fieldHasError}
        />
        <span
          style={{
            fontSize: 12,
            color: '#dc3545',
            display: fieldHasError ? 'block' : 'none'
          }}
        >{`${translate(id)} ${errors[`${name}`]?.message || 'is required'}`}</span>
      </FormGroup>
    </>
  );
});

export default CustomTypeHead;
