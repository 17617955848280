import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Media, Form, FormGroup, Label } from 'reactstrap';
import Breadcrumb from '../../layout/breadcrumb';
import {
  MyProfile,
  Bio,
  Website,
  Username,
  Address,
  EmailAddress,
  PostalCode,
  Country,
  City,
  AccountInformation,
  CompanyInformation,
  CEO,
  Employee,
  Edit,
  Update,
  Phone,
  StoreBio,
  StoreName,
  StoreType,
  IncludeCountryCode
} from '../../constant';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import CustomInput from '../../components/forms/CustomInput';
import CustomTypeHead from '../../components/ecommerce/customTypeHead';
import { clone, identity, isEmpty, isNull, pickBy } from 'lodash';
import { getAllCountries } from '../../redux/app/action';
import CustomEditor from '../../components/CustomEditor';
import { updateUserData } from '../../redux/auth/action';
import decodeHtmlEntity from '../../helper/htmlEntityDecode';
import { updateSellerInfo } from '../../redux/sellers/action';

const UserAccount = () => {
  const { t: translate } = useTranslation();
  const useFormObj = useForm();
  const dispatch = useDispatch();
  const [isPersonalProfileEdit, setIsPersonalProfileEdit] = useState(false);
  const [isCompanyProfileEdit, setIsCompanyProfileEdit] = useState(false);
  const { userData } = useSelector((state) => state.auth);
  const { info } = useSelector((state) => state.seller);
  const { allCountries } = useSelector((state) => state.app);
  const countries = !isNull(allCountries) ? allCountries.map(({ name }) => name) : [];

  useEffect(() => {
    if (isEmpty(allCountries)) {
      dispatch(getAllCountries());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(info)) {
      processEditProfile();
      processEditCompany();
    }
  }, [isCompanyProfileEdit, isPersonalProfileEdit, info]);

  const handlePersonalProfileEditBtn = () => {
    setIsPersonalProfileEdit(true);
  };
  const handleCompanyProfileEditBtn = () => {
    setIsCompanyProfileEdit(true);
  };

  const savePersonalProfileEditBtn = () => {
    const userId = userData.userId;
    const { email, bio, username } = useFormObj.watch();

    const data = {
      email,
      bio,
      username
    };

    dispatch(updateUserData(userId, data, () => setIsPersonalProfileEdit(false)));
  };

  const saveCompanyProfileEditBtn = () => {
    const userId = userData.userId;
    const { country, city, storeBio, address, state_province, postal_code, website, email, phoneNumber } = useFormObj.watch();
    const data = { country: country[0], city, storeBio, address, state_province, postal_code, website, email, phoneNumber };

    dispatch(updateSellerInfo(userId, data, () => setIsCompanyProfileEdit(false)));
  };

  const processEditCompany = () => {
    if (isCompanyProfileEdit) {
      let store = clone(info.store);
      store = pickBy(
        {
          ...store,
          address: store.billingAddress?.address01,
          state_province: store.billingAddress?.state_province,
          postal_code: store.billingAddress?.postal_code,
          storeType: info.sellerType?.name
        },
        identity
      );

      delete store.billingAddress;
      delete store.shippingAddress;
      Object.entries(store).forEach(([key, value]) => {
        if (key === 'country') {
          value = [value];
        }

        useFormObj.setValue(key, value);
      });
    }
  };

  const processEditProfile = () => {
    if (isPersonalProfileEdit) {
      const user = clone(userData);
      const data = pickBy(
        {
          username: user.meta?.username,
          bio: user.bio,
          email: user.email
        },
        identity
      );

      Object.entries(data).map(([key, value]) => useFormObj.setValue(key, value));
    }
  };

  return (
    <Fragment>
      <FormProvider {...useFormObj}>
        <Breadcrumb parent="Users" title={`${translate(AccountInformation)}`} />
        <Container fluid={true}>
          <div className="edit-profile">
            <Row>
              <Col xl="6">
                <Card>
                  <CardHeader>
                    <div className="d-flex flex-direction-row justify-content-between">
                      <h4 className="card-title mb-0">{translate(MyProfile)}</h4>
                      {!isPersonalProfileEdit && (
                        <button className="btn btn-primary btn-sm" onClick={handlePersonalProfileEditBtn}>
                          {translate(Edit)}
                        </button>
                      )}
                    </div>
                    <div className="card-options">
                      <a className="card-options-collapse" href="#javascript">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="#javascript">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row className="mb-2">
                        <div className="profile-title">
                          <div className="media">
                            <Media className="img-70 rounded-circle" alt="" src={require('../../assets/images/user/7.jpg')} />
                            <div className="media-body">
                              <h5 className="mb-1">{`${userData?.firstname} ${userData?.lastname}`}</h5>
                              <p>{info.isMain ? translate(CEO) : translate(Employee)}</p>
                            </div>
                          </div>
                        </div>
                      </Row>
                      <FormGroup>
                        <Label className="form-label">{Username}</Label>
                        {isPersonalProfileEdit ? <CustomInput name={'username'} /> : <p>{userData?.meta?.username}</p>}
                      </FormGroup>
                      <FormGroup>
                        <Label className="form-label">{EmailAddress}</Label>
                        {isPersonalProfileEdit ? <CustomInput name={'email'} /> : <p>{userData?.email}</p>}
                      </FormGroup>
                      <FormGroup>
                        {isPersonalProfileEdit ? (
                          <CustomEditor name={'bio'} label={Bio} onInputChange={(value) => useFormObj.setValue('bio', value)} />
                        ) : (
                          <>
                            <Label className="form-label">{Bio}</Label>
                            <p>{decodeHtmlEntity('span', userData?.bio)}</p>
                          </>
                        )}
                      </FormGroup>
                    </Form>
                  </CardBody>
                  <CardFooter className="text-end">
                    {isPersonalProfileEdit && (
                      <button className="btn btn-primary" onClick={savePersonalProfileEditBtn}>
                        {Update}
                      </button>
                    )}
                  </CardFooter>
                </Card>
              </Col>
              <Col xl="6">
                <div className="card">
                  <CardHeader>
                    <div className="d-flex flex-direction-row justify-content-between">
                      <div>
                        <h4 className="card-title mb-0">{translate(CompanyInformation)}</h4>
                        <span>
                          <i className="icofont icofont-close-circled"></i> Not verified
                        </span>
                      </div>
                      {!isCompanyProfileEdit && (
                        <div>
                          <button className="btn btn-primary btn-sm" onClick={handleCompanyProfileEditBtn}>
                            {translate(Edit)}
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="card-options">
                      <a className="card-options-collapse" href="#javascript">
                        <i className="fe fe-chevron-up"></i>
                      </a>
                      <a className="card-options-remove" href="#javascript">
                        <i className="fe fe-x"></i>
                      </a>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{StoreName}</Label>
                          {isCompanyProfileEdit ? <CustomInput name={'name'} disabled={true} /> : <p>{info?.store?.name}</p>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{EmailAddress}</Label>
                          {isCompanyProfileEdit ? <CustomInput name={'email'} /> : <p>{info?.store?.email}</p>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">
                            {Phone} {isCompanyProfileEdit && <i>{`(${IncludeCountryCode})`}</i>}
                          </Label>
                          {isCompanyProfileEdit ? <CustomInput name={'phoneNumber'} /> : <p>{info?.store?.phoneNumber}</p>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{Address}</Label>
                          {isCompanyProfileEdit ? <CustomInput name={'address'} /> : <p>{info?.store?.billingAddress?.address01}</p>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label>{City}</Label>
                          {isCompanyProfileEdit ? <CustomInput name={'city'} /> : <p>{info?.store?.city}</p>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{PostalCode}</Label>
                          {isCompanyProfileEdit ? <CustomInput name={'postal_code'} /> : <p>{info?.store?.billingAddress?.postal_code}</p>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{Country}</Label>
                          {isCompanyProfileEdit ? (
                            <CustomTypeHead
                              id={translate('storeCountry')}
                              data={countries}
                              label={translate('Country')}
                              labelOptions={{ useLabel: false }}
                              name={'country'}
                              typeHeadOptions={{ ...{ multiple: false } }}
                              onChangeSelection={(val) => {
                                useFormObj.setValue('country', val);
                              }}
                              selected={useFormObj.watch('country')}
                            />
                          ) : (
                            <p>{info?.store?.country}</p>
                          )}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{Website}</Label>
                          {isCompanyProfileEdit ? <CustomInput name={'website'} /> : <p>{info?.store?.website}</p>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label className="form-label">{StoreType}</Label>
                          {isCompanyProfileEdit ? <CustomInput name={'storeType'} disabled={true} /> : <p>{info?.sellerType?.name}</p>}
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <div className="mb-0">
                          {isCompanyProfileEdit ? (
                            <CustomEditor name={'storeBio'} label={StoreBio} onInputChange={(value) => useFormObj.setValue('storeBio', value)} />
                          ) : (
                            <>
                              <Label className="form-label">{StoreBio}</Label>
                              <p>{decodeHtmlEntity('span', info?.store?.storeBio)}</p>
                            </>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter className="text-end">
                    {isCompanyProfileEdit && (
                      <button className="btn btn-primary" onClick={saveCompanyProfileEditBtn}>
                        {Update}
                      </button>
                    )}
                  </CardFooter>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </FormProvider>
    </Fragment>
  );
};

export default UserAccount;
