import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Brand, MAX, MIN, Price } from '../../../constant';
import { fetchMarketplaceProductOptions } from '../../../redux/product/action';
import { capitalize, groupBy, isEmpty, startCase } from 'lodash';
import { Col, Input, Row } from 'reactstrap';

const MarketplaceFilter = () => {
  const [productOptions, setProductOptions] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMarketplaceProductOptions(processResult));
  }, []);

  const processResult = (data) => {
    if (isEmpty(data)) return;

    const brand = data.brand;
    const attributes = groupBy(data.attributes, 'key');

    setProductOptions({ brand, attributes });
  };

  const convertName = (name) => {
    if (name.length < 3) return name.toUpperCase();

    return startCase(name);
  };

  return (
    <Fragment>
      <div className="product-filter">
        <h6 className="f-w-600">{Brand}</h6>
        <div className="checkbox-animated mt-0">
          {productOptions.brand &&
            productOptions.brand.map(({ key, number }, index) => {
              return (
                <label className="d-block" key={index}>
                  <input className="checkbox_animated" onClick={() => {}} value={key} id={index} type="checkbox" data-original-title="" title="" />
                  {`${capitalize(key)} (${number})`}
                </label>
              );
            })}
        </div>
      </div>
      {productOptions.attributes &&
        Object.entries(productOptions.attributes).map(([key, value], indexKey) => {
          return (
            <div className="product-filter slider-product" key={indexKey}>
              <h6 className="f-w-600">{convertName(key)}</h6>
              <div className="color-selector">
                <ul>
                  {value.map(({ value: val, number }, i) => {
                    return (
                      <label className="d-block" key={i}>
                        <input className="checkbox_animated" onClick={() => {}} value={val} id={i} type="checkbox" data-original-title="" title="" />
                        {`${val} (${number})`}
                      </label>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}

      <div>
        <div className="product-filter pb-0">
          <h6 className="f-w-600">{Price}</h6>
          <Row>
            <Col>
              <Input className="form-control mr-2" type="text" placeholder={MIN} />
            </Col>
            <Col>
              <Input className="form-control" type="text" placeholder={MAX} />
            </Col>
          </Row>
        </div>
      </div>
    </Fragment>
  );
};

export default MarketplaceFilter;
