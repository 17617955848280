import { combineReducers } from 'redux';
import { appReducer } from './app/reducer';
import authReducer from './auth/reducer';
import brandReducer from './brands/reducer';
import categoryReducer from './category/reducer';
import Customizer from './customizer/reducer';
import minicategoryReducer from './minicategory/reducer';
import productReducer from './product/reducer';
import requestReducer from './requests/reducer';
import sellerReducer from './sellers/reducer';
import subcategoryReducer from './subcategory/reducer';

const reducers = combineReducers({
  Customizer,
  app: appReducer,
  auth: authReducer,
  requests: requestReducer,
  categories: categoryReducer,
  subcategories: subcategoryReducer,
  minicategories: minicategoryReducer,
  brands: brandReducer,
  product: productReducer,
  seller: sellerReducer
});

export default reducers;
