import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, FormGroup, Button, Row, Col, Media } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Fields, Value } from '../../constants';
import CustomTypeHead from '../../../../../components/ecommerce/customTypeHead';
import { Add } from '../../../../../constant';
import { isEmpty, isNil } from 'lodash';
import { preProcessProductVariation } from '../../../../../helper/productHelper';
import { useFormContext } from 'react-hook-form';
import CustomSelect from '../../../../../components/forms/CustomSelect';
import CustomInput from '../../../../../components/forms/CustomInput';
import CustomEditor from '../../../../../components/CustomEditor';
import { deleteFile, uploadFile } from '../../../../../redux/fileUpload/action';

export default function ProductVariation(props) {
  const { isEditMode } = props;
  const { getValues, setValue, unregister, register } = useFormContext();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { staticData } = useSelector((state) => state.product);
  const { locationData } = useSelector((state) => state.app);
  const [variationData, setVariationData] = useState([]);
  const [productVariationData, setProductVariationData] = useState([]);
  const [productVariationType, setProductVariationType] = useState('');
  const [productVariationValue, setProductVariationValue] = useState([]);
  const [productVariationPrice, setProductVariationPrice] = useState(0);
  const [productVariationDescription, setProductVariationDescription] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const [selectedImages, setSelectedImages] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const productVariationFromDB = getValues('productVariationFromDB');
  register('productVariationData');

  useEffect(() => {
    if (isEditMode && !isNil(productVariationFromDB)) {
      processProductVariationInEditMode();
    }
  }, [productVariationFromDB]);

  useEffect(() => {
    const disabled = !isEmpty(productVariationType) && !isEmpty(productVariationValue) && !isEmpty(productVariationPrice);
    setDisableBtn(!disabled);
    unregister('productVariationType');
    unregister('productVariationValue');
    unregister('productVariationPrice');
    unregister('productVariationDescription');
  }, [productVariationType, productVariationValue, productVariationPrice]);

  const handleVariationChange = (value) => {
    switch (value) {
      case Fields.Color:
        return setColorData();
      case Fields.Size:
        return setSizeData(value);
      default:
        return setVariationData([]);
    }
  };

  const setSizeData = (value) => {
    const allSizes = Object.entries(staticData)
      .map(([key]) => key)
      .filter((data) => data.includes(value) && data !== 'Other Size')
      .map((res) => staticData[`${res}`])
      .flat()
      .map((data) => data.value)
      .sort();

    setVariationData(allSizes);
  };

  const setColorData = () => {
    const colorVariation = staticData['Color Variation'];
    const colorNames = colorVariation.map(({ value }) => value);

    setVariationData(colorNames);
  };

  const handleProductVariationData = () => {
    const productVariationData = getValues('productVariationData') || [];
    const currency = locationData.currency;
    const currencySymbol = isEmpty(currency.symbol_native) ? currency.symbol : currency.symbol_native;
    const processedProductVariation = preProcessProductVariation(
      {
        type: productVariationType,
        price: productVariationPrice,
        value: productVariationValue.join(', '),
        description: productVariationDescription,
        images: selectedImages.map(({ fileId }) => fileId)
      },
      locationData
    );

    const data = {
      id: productVariationData.length + 1,
      type: productVariationType,
      price: `${currencySymbol} ${new Intl.NumberFormat().format(productVariationPrice)}`,
      value: productVariationValue.join(', '),
      description: productVariationDescription,
      imageSrc: selectedImages
    };

    setValue('productVariationData', [...productVariationData, processedProductVariation]);
    setProductVariationData([...productVariationData, data]);
    setProductVariationDescription('');
    setProductVariationPrice(0);
    setProductVariationValue([]);
  };

  const removeProductVariation = (id) => {
    const filteredProductVariations = productVariationData.filter((res) => res.id !== id);
    const productVariation = getValues('productVariationData');
    const image = productVariation[0].productVariationImages;

    if (!isEmpty(image)) {
      const imageId = image[0];
      dispatch(deleteFile(imageId));
    }
    setProductVariationData(filteredProductVariations);
    setValue('productVariationData', filteredProductVariations);
  };

  const handleImageUpload = (e) => {
    setIsLoading(true);
    const formData = new FormData();
    const imageFiles = Array.from(e.target.files);

    if (isEmpty(imageFiles)) return;

    imageFiles.forEach((file) => formData.append('files', file));

    dispatch(
      uploadFile(formData, (res, error) => {
        setStatusMessage('');
        setIsLoading(false);
        if (error) return processError(error);

        const imageURLs = res.map(({ fileLink, fileId }) => ({ fileLink, fileId }));
        setSelectedImages(selectedImages.concat(imageURLs));
      })
    );
  };

  const processProductVariationInEditMode = () => {
    const localData = productVariationFromDB.map((data) => {
      const { id, type, value, description, price, productVariationImages } = data;
      const imageSrc = isEmpty(productVariationImages) ? [] : productVariationImages.map(({ fileLink, fileId }) => ({ fileLink, fileId }));

      return {
        id,
        type,
        value,
        price: `${price.currencySymbol} ${new Intl.NumberFormat().format(price.price)}`,
        description,
        imageSrc
      };
    });

    const formData = productVariationFromDB.map((data) => {
      const { type, value, description, price, productVariationImages } = data;
      delete price.id;
      return {
        type,
        value,
        description,
        productVariationImages: productVariationImages.map(({ fileId }) => ({ fileId })),
        price: price
      };
    });

    setProductVariationData(localData);
    setValue('productVariationData', formData);
  };

  const processError = (error) => {
    setStatusMessage(error.join('; '));
  };

  const ProductVariationBox = ({ id, type, price, value, description, imageSrc = null, onRemove = () => {} }) => {
    const fileLink = isEmpty(imageSrc) ? '' : imageSrc[0].fileLink;
    return (
      <Row>
        <Col className="shadow-sm shadow-showcase p-10 my-1 b-primary rounded">
          <Row>
            <Col>
              <Media style={{ maxWidth: '200px', maxHeight: '200px' }} className="my-2" src={fileLink} />
            </Col>
            <Col>
              <div className="d-flex justify-content-between">
                <h6>
                  <small>{type}</small>
                </h6>
                <i style={{ cursor: 'pointer' }} onClick={() => onRemove(id)} className="fa fa-trash-o font-primary"></i>
              </div>
              <div className="row">
                <small>
                  <b>Value: </b>
                  {value}
                </small>
                <small>
                  <b>Price: </b>
                  {price}
                </small>
                <small>
                  <b>Description: </b>
                  {description}
                </small>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Card>
        <CardBody>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h6>Variation and Images</h6>
                {isLoading ? (
                  <div className="loader-box">
                    <div style={{ width: '25px', height: '25px', marginLeft: '10px', marginBottom: '10px' }} className="loader-22"></div>
                  </div>
                ) : null}
              </div>
              <small style={{ color: '#fd2e64' }}>{statusMessage}</small>
            </div>
          </div>

          <hr />
          {isEmpty(productVariationData) ? null : (
            <>
              {productVariationData.map(({ id, type, price, value, description, imageSrc }) => (
                <ProductVariationBox key={id} id={id} type={type} price={price} value={value} description={description} imageSrc={imageSrc} onRemove={removeProductVariation} />
              ))}
              <hr />
            </>
          )}
          <div className="row">
            <CustomSelect
              inputStyles={{ className: 'd-flex col' }}
              name={'productVariationType'}
              noOption={translate(Fields.Select)}
              menu={
                <>
                  <option value={Fields.Color}>{translate(Fields.Color)}</option>
                  <option value={Fields.Size}>{translate(Fields.Size)}</option>
                </>
              }
              onInputChange={(value) => {
                handleVariationChange(value);
                setProductVariationValue([]);
                setProductVariationType(value);
              }}
            />
            <CustomTypeHead
              id={translate(Value)}
              name={'productVariationValue'}
              onChangeSelection={(value) => setProductVariationValue(value)}
              inputStyles={{ className: 'd-flex col' }}
              data={variationData}
              selected={productVariationValue}
              typeHeadOptions={{ multiple: true }}
              labelOptions={{ useLabel: true }}
            />
            <CustomInput
              inputStyles={{ className: 'd-flex' }}
              name="productVariationPrice"
              value={productVariationPrice}
              onInputChange={(e) => setProductVariationPrice(e.target.value)}
              inputOptions={{ type: 'number' }}
              placeholder={translate(Fields.Price)}
            />
            <CustomInput
              inputStyles={{ className: 'd-flex col-md-12' }}
              name={'productVariationImage'}
              placeholder={'product variation Image'}
              inputOptions={{ type: 'file' }}
              onInputChange={(e) => {
                handleImageUpload(e);
                setValue('productVariationImage', e.target.value);
              }}
            />
            <CustomEditor
              label={Fields.ProductDescription}
              name={'productVariationDescription'}
              onInputChange={(value) => setProductVariationDescription(value)}
              placeholder={translate(Fields.Description)}
            />
            <FormGroup>
              <Button onClick={handleProductVariationData} disabled={disableBtn} color="primary">
                {Add}
              </Button>
            </FormGroup>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
