import { DELETE_BRANDS, GET_BRANDS, GET_BRANDS_DETAILS, SAVE_BRANDS, SET_BRANDS, UPDATE_BRAND } from '../actionTypes';

export const saveBrands = (payload) => {
  return {
    type: SAVE_BRANDS,
    payload
  };
};

export const updateBrand = (id, body) => ({
  type: UPDATE_BRAND,
  payload: { id, body }
});

export const setBrands = (payload) => {
  return {
    type: SET_BRANDS,
    payload
  };
};

export const getBrandDetails = (payload, callback) => {
  return {
    type: GET_BRANDS_DETAILS,
    payload,
    callback
  };
};

export const getBrands = () => {
  return {
    type: GET_BRANDS
  };
};

export const deleteBrands = (payload, callback) => {
  return {
    type: DELETE_BRANDS,
    payload,
    callback
  };
};
