import { put, takeLatest } from 'redux-saga/effects';
import { getApi, putApi } from '../../services';
import { requestHandler } from '../../services/handler';
import { GET_SELLER_INFO, UPDATE_SELLER_INFO } from '../actionTypes';
import { getSellerInfo, setSellerInfo } from './action';

const SELLER_URL = process.env.REACT_APP_SELLER_URL;

function* getSellerData(action) {
  const { userId } = action.payload;

  const callback = (data) => {
    window['store-ref'] = data.sellerId;
  };

  yield requestHandler(getApi.bind(this, `${SELLER_URL}/identifier/${userId}`), setSellerInfo, callback);
}

function* updateSellerInfo(action) {
  const {
    payload: { userId, body },
    callback
  } = action;

  const onSuccess = () => {
    return put(getSellerInfo(userId));
  };

  yield requestHandler(putApi.bind(this, `${SELLER_URL}/identifier/${userId}`, body), null, onSuccess, callback);
}

export function* WatcherSellerApp() {
  yield takeLatest(GET_SELLER_INFO, getSellerData);
  yield takeLatest(UPDATE_SELLER_INFO, updateSellerInfo);
}
