import React from 'react';
import DataTable from 'react-data-table-component';

export default function CustomDataTables({
  data = [],
  columns,
  onRowClick,
  isLoading = false,
  useServerPagination = false,
  pageNumber,
  itemNumber,
  totalRows = 0,
  onChangeRowsPage = () => {},
  changePage = () => {},
  sortingFunction = () => {}
}) {
  const Loader = () => (
    <div className="loader-box">
      <div className="loader-3"></div>
    </div>
  );

  if (useServerPagination) {
    return (
      <DataTable
        data={data}
        columns={columns}
        pagination
        paginationServer
        responsive
        pointerOnHover
        onRowClicked={onRowClick}
        progressPending={isLoading}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={onChangeRowsPage}
        onChangePage={changePage}
        progressComponent={<Loader />}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationDefaultPage={pageNumber}
        paginationPerPage={itemNumber}
        sortServer
        onSort={sortingFunction}
      />
    );
  }

  return (
    <DataTable
      data={data}
      columns={columns}
      pagination
      responsive
      pointerOnHover
      onRowClicked={onRowClick}
      progressPending={isLoading}
      progressComponent={<Loader />}
      paginationRowsPerPageOptions={[10, 25, 50, 100]}
    />
  );
}
