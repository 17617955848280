import { RESET_PRODUCT_LIST, SET_CURRENT_SELECTED_PRODUCT, SET_PRODUCTS, SET_PRODUCT_STATIC_DATA } from '../actionTypes';

const initialState = {
  staticData: {},
  products: [],
  currentSelectedProduct: {}
};

export default function productReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PRODUCT_STATIC_DATA:
      return { ...state, staticData: payload };
    case SET_PRODUCTS:
      return { ...state, products: payload };
    case SET_CURRENT_SELECTED_PRODUCT:
      return { ...state, currentSelectedProduct: payload };
    case RESET_PRODUCT_LIST:
      return { ...state, products: [] };
    default:
      return state;
  }
}
