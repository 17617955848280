import { SET_SELLER_INFO } from '../actionTypes';

const initialState = {
  info: {}
};

export default function sellerReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SELLER_INFO:
      return { ...state, info: payload };
    default:
      return state;
  }
}
