import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Name } from '../../../constant';

export default function BrandModal({ title, show = false, toggle, onSave, placeholder = 'Brand Name', btnText = 'Save', ...props }) {
  return (
    <Modal isOpen={show} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Form className="theme-form mega-form">
          <FormGroup>
            <Label className="col-form-label">{Name}</Label>
            <Input className="form-control" type="text" placeholder={placeholder} value={props?.name} onChange={(e) => props?.handleName(e.target.value)} />
          </FormGroup>
          {/*
          <hr className="mt-4 mb-4" />
          <h6>{Images}</h6>
          <FormGroup className="row">
            <Col sm="9">
              <Input className="form-control" type="file" multiple={true} />
            </Col>
          </FormGroup> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSave}>
          {btnText}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
