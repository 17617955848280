import { isUndefined } from 'lodash';
import { put, takeLatest } from 'redux-saga/effects';
import { getApi, postApi, putApi } from '../../services';
import { requestHandler } from '../../services/handler';
import { GET_LOGGED_IN_STATUS, GET_USER_DATA, LOGIN_USER, LOGOUT_USER, REDIRECT_TO_LOGIN_SCREEN, REGISTER_USER, UPDATE_USER_DATA } from '../actionTypes';
import { getUserData, setUserData } from './action';
import { getSellerInfo } from '../sellers/action';
import customToast from '../../components/alert/CustomToast';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

function* loginUser(action) {
  const { payload, callback } = action;

  const onError = (err) => {
    const { errors } = err;
    return errors.forEach((message) => customToast(message, 'error'));
  };

  yield requestHandler(postApi.bind(this, `${AUTH_URL}/login`, payload), null, callback, null, onError);
}

function* registerUser(action) {
  const { payload, callback } = action;

  const onError = (err) => {
    const { errors } = err;
    return errors.forEach((message) => customToast(message, 'error'));
  };

  yield requestHandler(postApi.bind(this, `${AUTH_URL}/register`, payload), null, callback, null, onError);
}

function* logoutUser(action) {
  const { callback } = action;
  yield requestHandler(postApi.bind(this, `${AUTH_URL}/logout`), null, callback);
}

function* getLoginStatus() {
  if (isUndefined(AUTH_URL)) {
    yield loginRedirect();
  }

  yield requestHandler(getApi.bind(this, `${AUTH_URL}/auth`));
}

function* loginRedirect() {
  window.location = '/login';
  yield;
}

function* getUserInfo() {
  const callback = (userData) => {
    const id = userData?.userId;
    if (!isUndefined(id)) return put(getSellerInfo(id));

    return;
  };

  yield requestHandler(getApi.bind(this, `${AUTH_URL}/info`), setUserData, callback);
}

function* updateUserInfo(action) {
  const {
    payload: { userId, body },
    callback
  } = action;

  yield requestHandler(putApi.bind(this, `${AUTH_URL}/${userId}`, body), getUserData, callback);
}

export function* WatcherAuthApp() {
  yield takeLatest(REGISTER_USER, registerUser);
  yield takeLatest(LOGIN_USER, loginUser);
  yield takeLatest(LOGOUT_USER, logoutUser);
  yield takeLatest(GET_LOGGED_IN_STATUS, getLoginStatus);
  yield takeLatest(REDIRECT_TO_LOGIN_SCREEN, loginRedirect);
  yield takeLatest(GET_USER_DATA, getUserInfo);
  yield takeLatest(UPDATE_USER_DATA, updateUserInfo);
}
