import React from 'react';
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import Loader from '../layout/loader';
// import AuthRoutes from './AuthRoutes';
import LayoutRoutes from './LayoutRoutes';
import { authRoutes } from './Routes';

const Routers = () => {
  useEffect(() => {
    const abortController = new AbortController();
    console.ignoredYellowBox = ['Warning: Each', 'Warning: Failed'];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };
  }, []);

  return (
    <BrowserRouter basename={'/'}>
      <>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/`} />} />
            <Route path={`/*`} element={<LayoutRoutes />} />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}
          </Routes>
        </Suspense>
      </>
    </BrowserRouter>
  );
};

export default Routers;
