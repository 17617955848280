export default {
  lang: 'ae',
  General: 'جنرال لواء',
  Dashboards: 'الحاجيات',
  Widgets: 'لوحات القيادة ',
  Dashboard: 'لوحة القيادة',
  Default: 'إفتراضي',
  Ecommerce: 'التجارة الإلكترونية',
  Chart: 'مخطط',
  Applications: 'التطبيقات',
  ReadytouseApps: 'جاهز لاستخدام التطبيقات',
  'Category Name is empty': 'اسم التصنيف فارغ',
  "Subcategory Name is empty'": 'اسم التصنيف الفرعي فارغ'
};
