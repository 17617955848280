export const ALL_PRODUCTS = 'All products';
export const ADD_PRODUCT = 'Add Products';
export const Attributes = 'Attributes';
export const ProductModel = 'Product Model';
export const Brand = 'Brand';
export const Subcategory = 'Subcategory';
export const Minicategory = 'Minicategory';
export const Quantity = 'Quantity';
export const Variation = 'Variation';
export const Value = 'Value';
export const Reset = 'Reset';
export const ValidRightComponents = {
  Electronics: 'electronics',
  Fashion: 'fashion'
};
export const Fields = {
  SKU: 'SKU',
  StorageTypes: 'Storage Type',
  StorageSizes: 'Storage Size',
  Weight: 'Weight',
  Warranty: 'Warranty',
  SelectABrand: 'Select a Brand',
  SelectACategory: 'Select a Category',
  SelectASubcategory: 'Select a Subcategory',
  SelectAMinicategory: 'Select a Minicategory',
  CostPrice: 'Cost Price',
  SalePrice: 'Sale Price',
  SelectAProcessor: 'Select a processor',
  SelectARam: 'Select a RAM size',
  PrimaryCamera: 'Primary Camera',
  SecondaryCamera: 'Secondary Camera',
  DisplayType: 'Display Type',
  Resolution: 'Resolution (pixel/dpi)',
  ScreenSize: 'Screen Size (inch)',
  Battery: 'Battery',
  Network: 'Networks',
  StartDate: 'Start Date',
  EndDate: 'End Date',
  Type: 'Type',
  Price: 'Price',
  Processor: 'Processor',
  RAM: 'RAM',
  HDD: 'Hard Disk Drive (HDD)',
  SSD: 'Solid State Drive (SDD)',
  ROM: 'ROM',
  grams: 'grams',
  kilograms: 'kilograms',
  lbs: 'lbs',
  pound: 'pound',
  Size: 'Size',
  Color: 'Color',
  Select: 'Select',
  Width: 'Width',
  Height: 'Height',
  Length: 'Length',
  or: 'or',
  AddNewItem: 'Add a new item',
  Description: 'Description',
  ProductTags: 'Product Tags',
  ProductImages: 'Product Images',
  ProductDescription: 'Product Description',
  ProductCondition: 'Product Condition',
  Used: 'Used',
  New: 'New',
  Refurbished: 'Refurbished',
  ManufactureYear: 'Manufacture Year',
  Merchant: 'Merchant',
  Vendor: 'Vendor',
  MerchantType1: 'I create own my products',
  MerchantType2: 'I sell wholesale products from different manufacturers',
  VendorType1: 'I want to drop-ship products',
  VendorType2: 'I want to buy from Merchant/Wholesalers and sell myself'
};
export const WHERE_WILL_THE_PRODUCT_BE_SOLD = 'countries you want this product to be sold';
export const PRODUCT_TAGS_INFORMATION = 'product tags are important for advertising';
export const productIdentifierTypes = {
  serial: 'serial',
  sku: 'sku'
};
export const Worldwide = 'Worldwide';

/**
///////// ERROR
*/

export const discountValueError = 'discount value should not be greater than 45%';
export const discountDateError = 'Start date should not be greater than End date';
