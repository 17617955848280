import { REQUEST_ENDED, REQUEST_ERROR, REQUEST_STARTED } from '../actionTypes';

const initialState = {
  requests: []
};

export default function requestReducer(state = initialState, { type, payload }) {
  switch (type) {
    case REQUEST_STARTED: {
      const existingRequest = state.requests.find((data) => data.name === payload.name);
      if (existingRequest) {
        return {
          ...state,
          requests: state.requests.map((data) => (data.name === payload.name ? { ...data, loading: true, error: null } : data))
        };
      }

      return {
        ...state,
        requests: [...state.requests, { name: payload.name, loading: true, error: null }]
      };
    }

    case REQUEST_ENDED:
      return {
        ...state,
        requests: state.requests.filter((data) => data.name !== payload.name)
      };

    case REQUEST_ERROR:
      return {
        ...state,
        requests: state.requests.map((data) =>
          data.name === payload.name
            ? {
                ...data,
                error: payload.error,
                loading: false
              }
            : data
        )
      };

    default:
      return state;
  }
}
