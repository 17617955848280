import { isEmpty, isNil, isUndefined } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Row, Col, Media } from 'reactstrap';
// import testImg from '../../../../../assets/images/other-images/receiver-img.jpg';
import CustomInput from '../../../../../components/forms/CustomInput';
import { Fields } from '../../constants';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { deleteFile, uploadFile } from '../../../../../redux/fileUpload/action';
import { AddProductContext } from '../../../../../helper/contextHelper';

export default function ProductImages() {
  const isEditMode = useContext(AddProductContext);
  const dispatch = useDispatch();
  const { getValues, setValue, register } = useFormContext();
  const [selectedImages, setSelectedImages] = useState([]);
  const [statusMessage, setStatusMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const fieldName = 'productImageData';
  const imagesFromDB = getValues('imagesFromDB');
  register(fieldName, { required: true });

  useEffect(() => {
    if (isEditMode) {
      processImagesInEditMode();
    }
  }, [imagesFromDB]);

  const handleImageUpload = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    const filesArray = Array.from(e.target.files);

    if (isEmpty(filesArray)) return;

    filesArray.forEach((file) => formData.append('files', file));
    const ref = getValues('sku');
    formData.append('ref', ref);
    formData.append('tag', 'productImages');
    const formValue = !isUndefined(getValues(fieldName)) ? getValues(fieldName) : [];

    dispatch(
      uploadFile(formData, (res, error) => {
        setStatusMessage('');
        setIsLoading(false);
        if (error) return processError(error);

        const imageIds = res.map(({ fileId }) => fileId);
        setValue(fieldName, formValue.concat(imageIds));

        const imageURLs = res.map(({ fileLink, fileId }) => ({ fileLink, fileId }));
        setSelectedImages(selectedImages.concat(imageURLs));
      })
    );
  };

  const removeUploadedImage = (id) => {
    const result = selectedImages.filter(({ fileId }) => fileId !== id);
    setValue(
      fieldName,
      result.map(({ fileId }) => fileId)
    );
    setSelectedImages(result);
    dispatch(deleteFile(id));
  };

  const processImagesInEditMode = () => {
    if (isNil(imagesFromDB) || isEmpty(imagesFromDB)) return;

    const imageIds = imagesFromDB.map(({ fileId }) => fileId);
    setValue(fieldName, imageIds);

    const imageURLs = imagesFromDB.map(({ fileLink, fileId }) => ({ fileLink, fileId }));
    setSelectedImages(selectedImages.concat(imageURLs));
  };

  const processError = (error) => {
    setStatusMessage(error.join('; '));
  };

  const ProductVariationBox = ({ images = [] }) => (
    <Row className="justify-content-between">
      {images.map(({ fileId, fileLink }, index) => (
        <Col key={index} sm={6}>
          <Media style={{ width: '100px', height: '100px' }} className="my-2" src={fileLink} />
          <div className="d-flex card-img-overlay">
            <div
              onClick={() => removeUploadedImage(fileId)}
              className="bg-primary rounded-circle text-center"
              style={{ width: '25px', height: '25px', padding: 2, cursor: 'pointer' }}
            >
              <i className="fa fa-trash-o font-light"></i>
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      <Card>
        <CardBody>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <h6>General Images </h6>
                {isLoading ? (
                  <div className="loader-box">
                    <div style={{ width: '25px', height: '25px', marginLeft: '10px', marginBottom: '10px' }} className="loader-22"></div>
                  </div>
                ) : null}
              </div>
              <small style={{ color: '#fd2e64' }}>{statusMessage}</small>
            </div>
          </div>
          <hr />
          <ProductVariationBox images={selectedImages} />
          <CustomInput
            name={'productImages'}
            label={Fields.ProductImages}
            placeholder={'images'}
            isRequired={isEmpty(imagesFromDB) || isNil(imagesFromDB)}
            onInputChange={handleImageUpload}
            inputOptions={{ type: 'file', multiple: true }}
            labelOptions={{ useLabel: false }}
          />
        </CardBody>
      </Card>
    </>
  );
}
