import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Name } from '../../../constant';

export default function CategoryModal({ title, show = false, toggle, onSave, placeholder = 'Category Name', ...props }) {
  return (
    <Modal isOpen={show} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <Form className="theme-form mega-form">
          <FormGroup>
            <Label className="col-form-label">{Name}</Label>
            <Input className="form-control" type="text" placeholder={placeholder} value={props?.categoryName} onChange={(e) => props?.handleCategoryName(e.target.value)} />
          </FormGroup>
          {/* <FormGroup>
            <Label className="col-form-label">{Description}</Label>
            <textarea
              className="form-control"
              rows="5"
              cols="5"
              placeholder="Description"
              value={props?.categoryDescription}
              onChange={(e) => props?.handleCategoryDescription(e.target.value)}
            ></textarea>
          </FormGroup>
          <hr className="mt-4 mb-4" />
          <h6>{Images}</h6>
          <FormGroup className="row">
            <Col sm="9">
              <Input className="form-control" type="file" multiple={true} />
            </Col>
          </FormGroup> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSave}>
          {'Save'}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
