import React, { useContext, useEffect, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, FormGroup, Label, CardHeader } from 'reactstrap';
import CustomTypeHead from '../../../../components/ecommerce/customTypeHead';
import { Availability, Category, Month, ProductName, Week, Year } from '../../../../constant';
import { getAllCategory, getSubcategoriesForCategory } from '../../../../redux/category/action';
import {
  Brand,
  Fields,
  Minicategory,
  productIdentifierTypes,
  ProductModel,
  PRODUCT_TAGS_INFORMATION,
  Quantity,
  Subcategory,
  WHERE_WILL_THE_PRODUCT_BE_SOLD,
  Worldwide
} from '../constants';
import { getProductIdentifier } from '../../../../redux/product/action';
import { getMinicategoriesForSubcategory } from '../../../../redux/subcategory/action';
import { getBrands } from '../../../../redux/brands/action';
import { processCustomEntryForProductDescription } from '../../../../helper';
import CustomEditor from '../../../../components/CustomEditor';
import { useFormContext } from 'react-hook-form';
import CustomInput from '../../../../components/forms/CustomInput';
import CustomSelect from '../../../../components/forms/CustomSelect';
import { AddProductContext } from '../../../../helper/contextHelper';

export default function Left() {
  const { isEditMode } = useContext(AddProductContext);
  const { setValue, getValues, setError, clearErrors } = useFormContext();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const { locationData, allCountries } = useSelector((state) => state.app);
  const { allCategory, subcategories } = useSelector((state) => state.categories);
  const { minicategories } = useSelector((state) => state.subcategories);
  const { allBrands } = useSelector((state) => state.brands);
  const { currentSelectedProduct } = useSelector((state) => state.product);
  const [productIdentifier, setProductIdentifier] = useState('');
  const [metaData, setMetaData] = useState([]);
  const countries = !isEmpty(locationData)
    ? allCountries.filter(({ iso2, name }) => iso2 !== locationData.country.isoCode && name !== locationData.country.name).map(({ name }) => name)
    : [];
  const currencySymbol = !isEmpty(locationData) ? `(${locationData.currency.symbol})` : null;

  useEffect(() => {
    if (isEmpty(allCategory)) {
      dispatch(getAllCategory());
    }

    if (isEmpty(allBrands)) {
      dispatch(getBrands());
    }

    fetchProductIdentifier();
  }, []);

  useEffect(() => {
    const cat = getValues('category');

    if (!isUndefined(cat) && !isEmpty(cat)) {
      const id = Number(cat);
      dispatch(getSubcategoriesForCategory(id));
    }
  }, [getValues('category')]);

  useEffect(() => {
    fetchSubcategory();
  }, [getValues('subcategory')]);

  useEffect(() => {
    if (isEditMode && !isEmpty(currentSelectedProduct)) {
      fetchSubcategory();
    }
  }, [currentSelectedProduct]);

  const validateAvailability = (value) => {
    if (value.includes(Worldwide) && value.length > 1) {
      setError('availability', { type: 'custom', message: `- "${Worldwide}" should not be selected with other countries` });
    } else {
      clearErrors('availability');
    }
  };

  const fetchProductIdentifier = () => {
    const sku = getValues('sku');
    if (!isUndefined(sku) && !isEmpty(sku)) return;

    const cb = (response) => {
      setValue('sku', response);
      setProductIdentifier(response);
    };

    dispatch(getProductIdentifier(productIdentifierTypes.sku, cb));
  };

  const fetchSubcategory = () => {
    const subcat = getValues('subcategory');

    if (!isUndefined(subcat) && !isEmpty(subcat)) {
      const id = Number(subcat);
      dispatch(getMinicategoriesForSubcategory(id));
    }
  };

  const handleProductTagsChange = (value) => {
    const transformedValue = processCustomEntryForProductDescription(value);
    setValue('productMetaTag', transformedValue);
    setMetaData(transformedValue);
  };

  return (
    <Card>
      <CardHeader>
        <h5>Product Data</h5>
      </CardHeader>
      <CardBody>
        <CustomInput label={ProductName} name={'productName'} floating={true} isRequired={true} />
        <CustomInput label={ProductModel} name={'productModel'} floating={true} isRequired={true} />
        <CustomInput label={Fields.SKU} name={'sku'} value={productIdentifier} floating={true} isRequired={true} readOnly={true} />
        <CustomSelect
          floating={true}
          label={Brand}
          noOption={Fields.SelectABrand}
          data={allBrands}
          name={'brand'}
          isRequired={true}
          menu={allBrands.map(({ brandId, brandName }, index) => (
            <option key={index} value={brandId}>
              {brandName}
            </option>
          ))}
        />
        <CustomSelect
          floating={true}
          label={Category}
          noOption={Fields.SelectACategory}
          name={'category'}
          isRequired={true}
          disabled={isEditMode}
          menu={allCategory.map(({ categoryId, name }, index) => (
            <option key={index} value={categoryId}>
              {name}
            </option>
          ))}
        />
        <CustomSelect
          floating={true}
          label={Subcategory}
          noOption={Fields.Select}
          name={'subcategory'}
          isRequired={true}
          menu={subcategories.map(({ subcategoryId, name }, index) => (
            <option key={index} value={subcategoryId}>
              {name}
            </option>
          ))}
        />
        <CustomSelect
          floating={true}
          label={Minicategory}
          noOption={Fields.Select}
          name={'minicategory'}
          isRequired={false}
          menu={minicategories.map(({ minicategoryId, name }, index) => (
            <option key={index} value={minicategoryId}>
              {name}
            </option>
          ))}
        />
        <CustomInput label={Quantity} name={'quantity'} floating={true} isRequired={true} inputOptions={{ type: 'number' }} />
        <CustomInput
          label={Fields.CostPrice}
          name={'costPrice'}
          floating={true}
          isRequired={true}
          disabled={isEditMode}
          labelOptions={{ symbol: currencySymbol, useLabel: true }}
          inputOptions={{ type: 'number' }}
        />
        <CustomInput
          label={Fields.SalePrice}
          name={'salePrice'}
          floating={true}
          isRequired={true}
          labelOptions={{ symbol: currencySymbol, useLabel: true }}
          inputOptions={{ type: 'number' }}
        />
        <FormGroup>
          <Label className="col-form-label">{translate(Fields.Warranty)}</Label>
          <div className="row row-cols-sm">
            <div className="col-sm-6">
              <CustomSelect
                floating={false}
                label={Fields.Warranty}
                noOption={Fields.Select}
                name={'warranty.type'}
                menu={
                  <>
                    <option value={translate(Year)}>{translate(Year)}</option>
                    <option value={Month}>{translate(Month)}</option>
                    <option value={Week}>{translate(Week)}</option>
                  </>
                }
                labelOptions={{ useLabel: false }}
              />
            </div>
            <CustomInput
              inputStyles={{ className: 'col-sm-6' }}
              name={'warranty.value'}
              placeholder={`${translate(Fields.Warranty)}`.toLowerCase()}
              labelOptions={{ useLabel: false }}
              inputOptions={{ type: 'number' }}
            />
          </div>
        </FormGroup>
        <CustomInput label={Fields.ManufactureYear} name={'manufactureYear'} placeholder={`${translate(Year)}`.toLowerCase()} labelOptions={{ useTopLabel: true }} />
        <CustomSelect
          label={Fields.ProductCondition}
          noOption={Fields.Select}
          name={'productCondition'}
          isRequired={true}
          menu={
            <>
              <option value={Fields.New}>{translate(Fields.New)}</option>
              <option value={Fields.Refurbished}>{translate(Fields.Refurbished)}</option>
              <option value={Fields.Used}>{translate(Fields.Used)}</option>
            </>
          }
          labelOptions={{ useTopLabel: true }}
        />
        {/* <CustomTypeHead
          id={translate(Availability)}
          data={[Worldwide, ...countries]}
          label={translate(Availability)}
          name={'availability'}
          placeholder={translate(WHERE_WILL_THE_PRODUCT_BE_SOLD)}
          typeHeadOptions={{ ...{ selected: getValues('availability'), multiple: true } }}
          onChangeSelection={(val) => {
            validateAvailability(val);
            setValue('availability', val);
          }}
          selected={getValues('availability')}
          isRequired={true}
        /> */}
        {/* <CustomTypeHead
          id={translate(Fields.ProductTags)}
          data={metaData}
          label={translate(Fields.ProductTags)}
          name={'productMetaTag'}
          selected={metaData}
          placeholder={translate(PRODUCT_TAGS_INFORMATION)}
          typeHeadOptions={{ multiple: true, allowNew: true }}
          onChangeSelection={handleProductTagsChange}
        /> */}

        <CustomEditor label={Fields.ProductDescription} isRequired={true} name={'productDescription'} onInputChange={(value) => setValue('productDescription', value)} />
      </CardBody>
    </Card>
  );
}
