import React from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';

export default function CustomSelect({
  name,
  label,
  isRequired = false,
  noOption = '',
  floating = false,
  menu,
  onInputChange = () => {},
  fieldOptions = {},
  inputStyles = { className: '', sx: {} },
  labelOptions = { useLabel: true, useTopLabel: false },
  disabled = false
}) {
  const { t: translate } = useTranslation();
  const {
    register,
    formState: { errors, touchedFields },
    watch,
    setValue,
    getValues
  } = useFormContext();
  const fieldHasError = touchedFields[`${name}`] && isRequired && isEmpty(watch(name));
  const showErrorFromSubmit = !isUndefined(errors[`${name}`]) && isEmpty(watch(name));
  const value = getValues(name);

  return (
    <FormGroup floating={floating} className={inputStyles.className} style={inputStyles.sx}>
      {!floating && labelOptions.useTopLabel && !labelOptions.useLabel ? <Label className="col-form-label">{translate(label)}</Label> : null}
      <Input
        type="select"
        className="form-control"
        invalid={fieldHasError || showErrorFromSubmit}
        value={value}
        {...register(name, { required: { value: isRequired, message: `${label} is required` }, ...fieldOptions })}
        onChange={(e) => {
          setValue(name, e.target.value);
          onInputChange(e.target.value);
        }}
        disabled={disabled}
      >
        <option value="">{translate(noOption)}</option>
        {menu}
      </Input>
      <FormFeedback>{`${translate(errors[`${name}`]?.message)} `}</FormFeedback>
      {labelOptions.useLabel && !labelOptions.useTopLabel ? <Label for={name}>{translate(label)}</Label> : null}
    </FormGroup>
  );
}
