import { takeLatest } from 'redux-saga/effects';
import { getApi } from '../../services';
import { requestHandler } from '../../services/handler';
import { GET_ALL_COUNTRIES, GET_ALL_CURRENCIES_ISO, GET_LOCATION_DATA } from '../actionTypes';
import { setAllCountries, setAllCurrenciesIso, setLocationData } from './action';

const LOCATION_URL = process.env.REACT_APP_LOCATION_URL;

function* getIpInfo(action) {
  const { payload } = action;
  const { ip, baseCurrency } = payload;
  const url = `${LOCATION_URL}/ip-info?ip=${ip}&baseCurrency=${baseCurrency}`;
  yield requestHandler(getApi.bind(this, url), setLocationData, null, null);
}

function* getAllCountries() {
  const url = `${LOCATION_URL}/csc/countries`;
  yield requestHandler(getApi.bind(this, url), setAllCountries);
}

function* getCurrenciesIso() {
  const url = `${LOCATION_URL}/currency/all.iso`;
  yield requestHandler(getApi.bind(this, url), setAllCurrenciesIso);
}

export function* WatcherMainApp() {
  yield takeLatest(GET_LOCATION_DATA, getIpInfo);
  yield takeLatest(GET_ALL_COUNTRIES, getAllCountries);
  yield takeLatest(GET_ALL_CURRENCIES_ISO, getCurrenciesIso);
}
