import React from 'react';
import ProductImages from './attributes-layout/ProductImages';
import PriceVariation from './attributes-layout/PriceVariation';
import ProductVariation from './attributes-layout/ProductVariation';
import Discounts from './attributes-layout/Discounts';

export default function Middle() {
  return (
    <>
      <ProductImages />
      <PriceVariation />
      <ProductVariation />
      <Discounts />
    </>
  );
}
