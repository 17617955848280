import { isUndefined } from 'lodash';

export const getRequestStatus = (state, action) => {
  const response = state.requests.find((data) => data.name === action);
  if (isUndefined(response)) {
    return {
      name: action,
      loading: false,
      error: null
    };
  }

  return response;
};
