import { RESET_MINICATEGORY_FOR_SUBCATEGORY, SET_MINICATEGORY_FOR_SUBCATEGORY, SET_SUBCATEGORY } from '../actionTypes';

const initialState = {
  allSubcategory: [],
  minicategories: []
};

const subcategoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SUBCATEGORY:
      return { ...state, allSubcategory: payload };
    case SET_MINICATEGORY_FOR_SUBCATEGORY:
      return { ...state, minicategories: payload };
    case RESET_MINICATEGORY_FOR_SUBCATEGORY:
      return { ...state, minicategories: [] };
    default:
      return state;
  }
};

export default subcategoryReducer;
