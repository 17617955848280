import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../layout/breadcrumb';
import { Container, Row, Col, Card, CardBody, Button } from 'reactstrap';
import { generatePath, useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import { Action, Active, DO_YOU_WANT_TO_DELETE, Name, NO, Save, Status, Update, YES } from '../../../constant';
import CustomDataTables from '../../../components/table/CustomDataTables';
import internalRoutes from '../../../route/internalRoutes';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import _, { isEmpty } from 'lodash';
import Alerts from '../../../components/alert/CustomAlerts';
import { useTranslation } from 'react-i18next';
import { deleteBrands, getBrands, saveBrands, updateBrand } from '../../../redux/brands/action';
import BrandModal from './brandModal';
import { ADD_BRANDS, BRAND_NAME_IS_EMPTY, UPDATE_BRAND } from './constants';

export default function ListBrands() {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allBrands } = useSelector((state) => state.brands);
  const [openBrandModal, setOpenBrandModal] = useState(false);
  const [brandName, setBrandName] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [brandId, setBrandId] = useState(0);
  const toggleBrandModal = () => setOpenBrandModal(!openBrandModal);

  useEffect(() => {
    if (_.isEmpty(allBrands)) {
      dispatch(getBrands());
    }
  }, []);

  const handleSaveBrands = () => {
    if (brandName === '') {
      return toast.error(translate(BRAND_NAME_IS_EMPTY));
    }

    const data = {
      brandName
    };

    if (isEditMode) {
      dispatch(updateBrand(brandId, data));
    } else {
      dispatch(saveBrands(data));
    }

    toggleBrandModal();
    setBrandName('');
  };

  const handleEditBrand = (id) => {
    setIsEditMode(true);
    setBrandId(id);

    const brand = allBrands.find(({ brandId }) => brandId === id);
    setBrandName(brand.brandName);
    toggleBrandModal();
  };

  const handleDeleteBrand = (id) => {
    const brand = allBrands.find((brand) => brand.brandId === id);

    Alerts(
      `${translate(DO_YOU_WANT_TO_DELETE)} "${brand.brandName}"?`,
      null,
      'warning',
      {
        confirmButtonText: translate(YES),
        denyButtonText: translate(NO),
        showConfirmButton: true,
        showDenyButton: true
      },
      null,
      () => dispatch(deleteBrands(id))
    );
  };

  const handleRowClick = (row) => {
    navigate(generatePath(internalRoutes.GOTO_BRAND_DETAILS, { bId: row.brandId }));
  };

  const columns = [
    {
      name: translate(Name),
      selector: (row) => row.brandName,
      sortable: true
    },
    {
      name: translate(Status),
      cell: () => (
        <Button color="success" size="xs">
          {Active}
        </Button>
      )
    },
    {
      name: translate(Action),
      selector: (row) => row.action,
      cell: (row) => (
        <div>
          <i className="icofont icofont-ui-edit p-2" onClick={() => handleEditBrand(row.brandId)} />
          <i className="icofont icofont-ui-delete" style={{ color: '#e4566e' }} onClick={() => handleDeleteBrand(row.brandId)} />
        </div>
      )
    }
  ];

  return (
    <Fragment>
      <Breadcrumb parent="ecommerce" title="all brands" />
      <Container fluid={true}>
        <BrandModal
          title={isEditMode ? UPDATE_BRAND : ADD_BRANDS}
          show={openBrandModal}
          toggle={() => toggleBrandModal()}
          onSave={() => handleSaveBrands()}
          name={brandName}
          btnText={isEditMode ? Update : Save}
          categoryDescription={brandName}
          handleName={setBrandName}
        />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Button style={{ marginBottom: '10px' }} color="primary" onClick={() => toggleBrandModal()}>
                  {translate(ADD_BRANDS)}
                </Button>
                {!isEmpty(allBrands) && <CustomDataTables columns={columns} data={allBrands} onRowClick={(row, event) => handleRowClick(row, event)} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}
