import { RESET_SUBCATEGORY_FOR_CATEGORY, SET_CATEGORY, SET_SUBCATEGORY_FOR_CATEGORY } from '../actionTypes';

const initialState = {
  allCategory: [],
  subcategories: []
};

const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CATEGORY:
      return { ...state, allCategory: payload };
    case SET_SUBCATEGORY_FOR_CATEGORY:
      return { ...state, subcategories: payload };
    case RESET_SUBCATEGORY_FOR_CATEGORY:
      return { ...state, subcategories: [] };
    default:
      return state;
  }
};

export default categoryReducer;
