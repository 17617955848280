export default {
  lang: 'du',
  General: 'Algemeen',
  Dashboards: 'Dashboards',
  Widgets: ' widgets',
  Dashboard: 'Dashboard',
  Default: 'Standaard',
  Ecommerce: 'E-commerce',
  Chart: 'Grafiek',
  Applications: 'Toepassingen',
  ReadytouseApps: 'Klaar om apps te gebruiken'
};
