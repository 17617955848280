export default function formatDate(date) {
  const newDate = new Date(date);
  const [dd, mm, yy] = [newDate.getDate(), newDate.getMonth(), newDate.getFullYear()];
  const day = dd < 10 ? `0${dd}` : dd;
  const month = mm < 9 ? `0${mm + 1}` : mm + 1;

  return `${yy}-${month}-${day}`;
}

export function validateEndDateIsGreater(endDate, startDate) {
  const end = new Date(endDate);
  const start = new Date(startDate);

  const days = Math.floor((end - start) / (1000 * 60 * 60 * 24));
  return days > 0;
}
