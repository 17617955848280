import { SET_ALL_COUNTRIES, SET_ALL_CURRENCIES_ISO, SET_CLIENT_IP, SET_ERROR_RESPONSE, SET_LOADING, SET_LOCATION_DATA, SET_SUCCESS_RESPONSE } from '../actionTypes';

const initialState = {
  clientIP: '',
  locationData: {},
  allCountries: [],
  allCurrenciesIso: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: {},
  success: {}
};

export function appReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ERROR_RESPONSE:
      return { ...state, ...payload };
    case SET_SUCCESS_RESPONSE:
      return { ...state, ...payload };
    case SET_LOADING:
      return { ...state, isLoading: payload };
    case SET_CLIENT_IP:
      return { ...state, clientIP: payload };
    case SET_LOCATION_DATA:
      return { ...state, locationData: payload };
    case SET_ALL_COUNTRIES:
      return { ...state, allCountries: payload };
    case SET_ALL_CURRENCIES_ISO:
      return { ...state, allCurrenciesIso: payload };
    default:
      return state;
  }
}
