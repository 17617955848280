import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { toast, ToastContainer } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, NavItem, NavLink, Nav, TabContent, TabPane } from 'reactstrap';
import { Password, SignIn, EmailAddress, RememberPassword, ForgotPassword, CreateAccount, AUTH0 } from '../../constant';
import jwtLogo from '../../assets/images/jwt.svg';
import { loginUser, setIsAuthenticated, setLoginStatus } from '../../redux/auth/action';
import { useTranslation } from 'react-i18next';

const Logins = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  const [password, setPassword] = useState('');
  const [selected, setSelected] = useState('jwt');
  const [isLoading, setIsLoading] = useState(false);

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword);
  };

  const response = (data) => {
    if (!isEmpty(data)) {
      setIsLoading(false);
      dispatch(setIsAuthenticated(true));
      dispatch(setLoginStatus(true));
      navigate('/dashboard/');
    } else {
      setIsLoading(false);
    }
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (isEmpty(email)) {
      toast.error(translate('Email is empty'));
      setIsLoading(false);
      return;
    }

    if (isEmpty(password)) {
      toast.error(translate('Password is empty'));
      setIsLoading(false);
      return;
    }

    const data = { email, password };
    dispatch(loginUser(data, response));
  };

  return (
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col xs="12" className="p-0">
          <div className="login-card">
            <div>
              <div>
                <a className="logo" href="#javascript">
                  <img className="img-fluid for-dark" src={require('../../assets/images/logo/login.png')} alt="" />
                  <img className="img-fluid for-light" src={require('../../assets/images/logo/logo_dark.png')} alt="" />
                </a>
              </div>
              <div className="login-main login-tab">
                <Nav className="border-tab flex-column" tabs>
                  <NavItem>
                    <NavLink className={selected === 'jwt' ? 'active' : ''} onClick={() => setSelected('jwt')}>
                      <img src={jwtLogo} alt="" />
                      <span>{SignIn}</span>
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={selected} className="content-login">
                  <TabPane className="fade show" tabId={'jwt'}>
                    <Form className="theme-form">
                      <h4>{SignIn}</h4>
                      <p>{'Enter your email & password to login'}</p>
                      <FormGroup>
                        <Label className="col-form-label">{EmailAddress}</Label>
                        <Input className="form-control" type="email" required="" placeholder="" onChange={(e) => setEmail(e.target.value)} />
                      </FormGroup>
                      <div className="mb-3 position-relative">
                        <Label className="col-form-label">{Password}</Label>
                        <Input
                          className="form-control"
                          type={togglePassword ? 'text' : 'password'}
                          name="login[password]"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required=""
                          placeholder="*********"
                        />
                        <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}>
                          <span className={togglePassword ? '' : 'show'}></span>
                        </div>
                      </div>
                      <div className="login-btn mb-0">
                        <div className="checkbox ms-3">
                          <Input id="checkbox1" type="checkbox" />
                          <Label className="text-muted" for="checkbox1">
                            {RememberPassword}
                          </Label>
                        </div>
                        <a className="link" href="#javascript">
                          {ForgotPassword}
                        </a>

                        {isLoading ? (
                          <div className="loader-box">
                            <div className="loader-19"></div>
                          </div>
                        ) : (
                          <Button onClick={(e) => handleLogin(e)} color="primary">
                            {SignIn}
                          </Button>
                        )}
                      </div>
                      <p className="mt-4 mb-0">
                        {"Don't have account?"}
                        <a className="ms-2" href="/register">
                          {CreateAccount}
                        </a>
                      </p>
                    </Form>
                  </TabPane>
                  <TabPane className="fade show" tabId="auth0">
                    <div className="auth-content">
                      <img src={require('../../assets/images/auth-img.svg')} alt="" />
                      <h4>{'Welcome to login with Auth0'}</h4>
                      <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                      <Button color="info">{AUTH0}</Button>
                    </div>
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <ToastContainer autoClose={3000} />
    </Container>
  );
};

export default Logins;
