// dashbaord
import Default from '../components/dashboard/default/index';
import Ecommerce from '../components/dashboard/ecommerce/index';

// Support Ticket
import StarterKit from '../components/starter-kits/index';
import Logins from '../pages/authentication/login';
import Register from '../pages/authentication/register';
import BrandDetail from '../pages/ecommerce/brands/brand-detail';
import ListBrands from '../pages/ecommerce/brands/list-brands';
import CategoryDetail from '../pages/ecommerce/category/category-detail';
import ListCategory from '../pages/ecommerce/category/list-category';
import AddProduct from '../pages/ecommerce/inventory/add-products/add-product';
import ListProducts from '../pages/ecommerce/inventory/list';
import MarketPlace from '../pages/ecommerce/marketplace';
import UserAccount from '../pages/user/account';

export const authRoutes = [
  { path: `${process.env.PUBLIC_URL}/login`, Component: <Logins /> },
  { path: `${process.env.PUBLIC_URL}/register`, Component: <Register /> }
];

export const routes = [
  { path: `${process.env.PUBLIC_URL}/dashboard`, Component: <Default /> },
  { path: `${process.env.PUBLIC_URL}/store`, Component: <Ecommerce /> },
  { path: `${process.env.PUBLIC_URL}/category`, Component: <ListCategory /> },
  { path: `${process.env.PUBLIC_URL}/category/:catId`, Component: <CategoryDetail /> },
  { path: `${process.env.PUBLIC_URL}/brands`, Component: <ListBrands /> },
  { path: `${process.env.PUBLIC_URL}/brands/:bId`, Component: <BrandDetail /> },
  { path: `marketplace`, Component: <MarketPlace /> },
  { path: `${process.env.PUBLIC_URL}/products`, Component: <ListProducts /> },
  { path: `${process.env.PUBLIC_URL}/products/new`, Component: <AddProduct /> },
  { path: `${process.env.PUBLIC_URL}/products/edit/:productId`, Component: <AddProduct edit={true} /> },
  { path: `${process.env.PUBLIC_URL}/user/profile`, Component: <UserAccount /> },
  // { path: `${process.env.PUBLIC_URL}/products/:productId`, Component: <StarterKit /> },
  //Support Ticket
  { path: `${process.env.PUBLIC_URL}/starter-kits/sample-page`, Component: <StarterKit /> }
];
