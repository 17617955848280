import {
  GET_ALL_COUNTRIES,
  GET_ALL_CURRENCIES_ISO,
  GET_LOCATION_DATA,
  SET_ALL_COUNTRIES,
  SET_ALL_CURRENCIES_ISO,
  SET_CLIENT_IP,
  SET_ERROR_RESPONSE,
  SET_LOADING,
  SET_LOCATION_DATA,
  SET_SUCCESS_RESPONSE
} from '../actionTypes';

export const setLoading = (status) => {
  return {
    type: SET_LOADING,
    payload: status
  };
};

export const setErrorResponse = (error) => {
  return {
    type: SET_ERROR_RESPONSE,
    payload: error
  };
};

export const setSuccessResponse = (data) => {
  return {
    type: SET_SUCCESS_RESPONSE,
    payload: data
  };
};

export const setClientIP = (ip) => ({
  type: SET_CLIENT_IP,
  payload: ip
});

export const getLocationData = (ip, baseCurrency = 'USD') => ({
  type: GET_LOCATION_DATA,
  payload: { ip, baseCurrency }
});

export const setLocationData = (payload) => ({
  type: SET_LOCATION_DATA,
  payload
});

export const getAllCountries = () => ({
  type: GET_ALL_COUNTRIES
});

export const setAllCountries = (payload) => ({
  type: SET_ALL_COUNTRIES,
  payload
});

export const getAllCurrenciesIso = () => ({ type: GET_ALL_CURRENCIES_ISO });
export const setAllCurrenciesIso = (payload) => ({ type: SET_ALL_CURRENCIES_ISO, payload });
