import { Home, Briefcase, HardDrive, Package } from 'react-feather';

export const MENUITEMS = [
  {
    menutitle: 'Dashboard',
    menucontent: 'Home, Store',
    Items: [
      {
        icon: Home,
        path: `${process.env.PUBLIC_URL}/dashboard`,
        title: 'Home',
        type: 'link',
        active: false
      },
      {
        icon: Briefcase,
        active: false,
        path: `${process.env.PUBLIC_URL}/store`,
        title: 'Store',
        type: 'link'
      }
    ]
  },
  {
    menutitle: 'Ecommerce',
    menucontent: 'categories, products, brands',
    Items: [
      {
        title: 'Product Categorization',
        icon: HardDrive,
        type: 'sub',
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/category`, title: 'Category', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/brands`, title: 'Brands', type: 'link' }
        ]
      },
      {
        title: 'Products',
        icon: Package,
        type: 'sub',
        active: false,
        children: [
          { path: `${process.env.PUBLIC_URL}/marketplace`, title: 'Marketplace', type: 'link' },
          { path: `${process.env.PUBLIC_URL}/products`, title: 'Inventory', type: 'link' }
        ]
      }
    ]
  }
];
