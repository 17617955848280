export default {
  lang: 'fr',
  General: 'Générale',
  Dashboards: 'Tableaux de bord',
  Widgets: ' widgets',
  Dashboard: 'Tableau de bord',
  Default: 'Défaut',
  Ecommerce: 'Commerce électronique',
  Chart: 'Graphique',
  Applications: 'Applications',
  ReadytouseApps: "Applications prêtes à l'emploi"
};
