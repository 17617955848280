export default {
  lang: 'en',
  General: 'General',
  Dashboards: 'Dashboards',
  Widgets: 'Widgets',
  Dashboard: 'Dashboard',
  Default: 'Default',
  Ecommerce: 'Ecommerce',
  Chart: 'Chart',
  Applications: 'Applications',
  ReadytouseApps: 'Ready to use Apps',
  Home: 'Home',
  Store: 'Store',
  'Category Name is empty': 'Category Name is empty',
  "Subcategory Name is empty'": 'Subcategory Name is empty'
};
