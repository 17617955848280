export const SortTranslation = {
  LowestPrices: {
    field: 'price',
    direction: 'asc',
    text: 'LowestPrices',
    alias: 'price'
  },
  HighestPrices: {
    field: 'price',
    direction: 'desc',
    text: 'HighestPrices',
    alias: 'price'
  }
};

export const validSortFields = ['price'];
export const validSortValues = ['asc', 'desc'];
