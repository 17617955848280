export default {
  lang: 'pt',
  General: 'Geral',
  Dashboards: 'Painéis',
  Widgets: ' Widgets',
  Dashboard: 'painel de controle',
  Default: 'Padrão',
  Ecommerce: 'Comércio eletrônico',
  Chart: 'Gráfico',
  Applications: 'Formulários',
  ReadytouseApps: 'Aplicativos prontos para usar'
};
