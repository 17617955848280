import {
  GET_LOGGED_IN_STATUS,
  GET_USER_DATA,
  LOGIN_USER,
  LOGOUT_USER,
  REDIRECT_TO_LOGIN_SCREEN,
  REGISTER_USER,
  RESET_USER_DATA,
  SET_AUTHENTICATION_STATUS,
  SET_LOGGED_IN_STATUS,
  SET_USER_DATA,
  UPDATE_USER_DATA
} from '../actionTypes';

export const loginUser = (data, callback) => {
  return {
    type: LOGIN_USER,
    payload: data,
    callback
  };
};

export const createUser = (payload, callback = null) => {
  return {
    type: REGISTER_USER,
    payload,
    callback
  };
};

export const logOutUser = (callback) => {
  return {
    type: LOGOUT_USER,
    callback
  };
};

export const setIsAuthenticated = (payload) => {
  return {
    type: SET_AUTHENTICATION_STATUS,
    payload
  };
};

export const getLoginStatus = () => {
  return {
    type: GET_LOGGED_IN_STATUS
  };
};

export const setLoginStatus = (payload) => {
  return {
    type: SET_LOGGED_IN_STATUS,
    payload
  };
};

export const setUserData = (data) => ({
  type: SET_USER_DATA,
  payload: data
});

export const getUserData = () => ({
  type: GET_USER_DATA
});

export const updateUserData = (userId, body, callback = () => {}) => ({
  type: UPDATE_USER_DATA,
  payload: {
    userId,
    body
  },
  callback
});

export const redirectToLogin = () => {
  return {
    type: REDIRECT_TO_LOGIN_SCREEN
  };
};

export const resetUserData = () => ({ type: RESET_USER_DATA });
