import React from 'react';
import { isNull } from 'lodash';

export default function CustomToken({ option, index, onRemove, processOptions = null, hideRemoveBtn = false }) {
  let optionValue = option;

  if (!isNull(processOptions)) {
    optionValue = processOptions(option);
  }

  return (
    <div key={index} className="mx-1 my-1" style={{ cursor: 'pointer' }}>
      <div className="bg-primary rounded">
        <span className="p-2">
          <small className="f-12">{optionValue}</small>
        </span>
        {hideRemoveBtn ? null : <i className="fa fa-times m-1" onClick={() => onRemove(optionValue)}></i>}
      </div>
    </div>
  );
}
