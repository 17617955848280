import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, FormGroup, Button, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { isEmpty, isUndefined } from 'lodash';
import { Fields } from '../../constants';
import CustomTypeHead from '../../../../../components/ecommerce/customTypeHead';
import { Add, Country } from '../../../../../constant';
import { convertGroupCurrencies } from '../../../../../redux/product/action';
import { getAllCurrenciesIso } from '../../../../../redux/app/action';
import { preProcessPriceVariation } from '../../../../../helper/productHelper';
import CustomInput from '../../../../../components/forms/CustomInput';
import { useFormContext } from 'react-hook-form';

export default function PriceVariation() {
  const { setValue, getValues, unregister } = useFormContext();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { allCountries, locationData, allCurrenciesIso } = useSelector((state) => state.app);
  const [countries, setCountries] = useState([]);
  const [processedPriceVariation, setProcessedPriceVariation] = useState([]);
  const [priceVariationCountries, setPriceVariationCountries] = useState([]);
  const [priceVariationAmount, setPriceVariationAmount] = useState('');
  const [disableBtn, setDisableBtn] = useState(true);
  const currencySymbol = !isEmpty(locationData) ? `(${locationData.currency.symbol})` : null;

  useEffect(() => {
    if (isEmpty(allCurrenciesIso)) {
      dispatch(getAllCurrenciesIso());
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(allCurrenciesIso) && !isEmpty(allCountries) && !isEmpty(locationData)) {
      const res = filterCountriesWithCurrency();
      setCountries(res);
      unregister('priceVariation.countries');
      unregister('priceVariation.amount');
    }
  }, [allCurrenciesIso, allCountries, locationData]);

  useEffect(() => {
    const disabled = !isEmpty(priceVariationCountries) && !isEmpty(priceVariationAmount);
    setDisableBtn(!disabled);
  }, [priceVariationCountries, priceVariationAmount]);

  const filterCountriesWithCurrency = () => allCountries.filter(({ currency }) => allCurrenciesIso.find((res) => res === currency)).map(({ name }) => name);

  const handlePriceVariationData = () => {
    const clientCurrency = locationData.currencyIso;
    const filteredCountries = allCountries.filter(({ name }) => priceVariationCountries.some((data) => data === name));
    const toData = filteredCountries.map(({ currency, iso3 }) => ({ currencyIso: currency, countryIso: iso3 }));
    const requestData = { amount: priceVariationAmount, from: clientCurrency, to: toData };
    dispatch(
      convertGroupCurrencies(requestData, (response) => {
        const processedResult = processResponseData(response, filteredCountries);
        const data = preProcessPriceVariation(response);
        setValue('priceVariation', data);
        setProcessedPriceVariation(processedResult);
      })
    );
  };

  const processResponseData = (data, filteredCountries) =>
    data.map((res, index) => {
      const { emoji, name } = filteredCountries.find((fc) => fc.currency === res.to && fc.iso3 === res.countryIso);
      return {
        id: index++,
        name,
        flag: emoji,
        amount: !isEmpty(data.symbol_native) ? res.format.with_symbol : res.format.with_native_symbol
      };
    });

  const removePriceVariation = (id) => {
    if (isEmpty(processedPriceVariation)) return;

    const filteredPriceVariations = processedPriceVariation.filter((res) => res.id !== id);
    const countryNames = filteredPriceVariations.map(({ name }) => name);

    setProcessedPriceVariation(filteredPriceVariations);
    setPriceVariationCountries(countryNames);

    const pv = getValues['priceVariation'];
    if (!isUndefined(pv) || !isEmpty(pv)) {
      const filteredData = pv.filter((_, index) => index !== id);
      setValue('priceVariation', filteredData);
    }
  };

  const PriceVariationBox = ({ id, countryName = '', price = '', flag = '', onRemove = () => {} }) => (
    <div style={{ width: '47%' }} className="shadow-sm shadow-showcase p-10 mx-1 my-1 b-primary rounded">
      <div className="d-flex justify-content-between">
        <h6>
          <small>{`${countryName} ${flag}`}</small>
        </h6>
        <i style={{ cursor: 'pointer' }} onClick={() => onRemove(id)} className="fa fa-trash-o font-primary"></i>
      </div>
      <small>{price}</small>
    </div>
  );

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex flex-row justify-content-between">
            <h6>{`Price ${currencySymbol}`}</h6>

            <span className="f-20">{locationData?.flags?.emoji}</span>
          </div>
          <hr />
          <Row>
            {isEmpty(processedPriceVariation) ? null : (
              <>
                {processedPriceVariation.map(({ id, name, flag, amount }) => (
                  <PriceVariationBox key={id} id={id} countryName={name} price={amount} flag={flag} onRemove={removePriceVariation} />
                ))}
                <hr />
              </>
            )}
          </Row>
          <div className="row row-cols-sm-3 theme-form mt-3 billing-form">
            <CustomTypeHead
              id={translate(Country)}
              name={'priceVariation.countries'}
              placeholder={translate(Country)}
              onChangeSelection={(value) => setPriceVariationCountries(value)}
              inputStyles={{ className: 'col-md-12' }}
              data={countries}
              selected={priceVariationCountries}
              typeHeadOptions={{
                hideRemoveBtn: false,
                multiple: true,
                onRemoveChange: (option) => {
                  const index = processedPriceVariation.findIndex(({ name }) => name === option);
                  removePriceVariation(index);
                }
              }}
            />
            <CustomInput
              name={'priceVariation.amount'}
              value={priceVariationAmount}
              onInputChange={(e) => setPriceVariationAmount(e.target.value)}
              placeholder={translate(Fields.Price)}
              inputStyles={{ className: 'd-flex col-md-12' }}
              inputOptions={{ type: 'number' }}
            />
            <FormGroup className="d-flex col-md-4">
              <Button disabled={disableBtn} color="primary" onClick={handlePriceVariationData}>
                {Add}
              </Button>
            </FormGroup>
          </div>
        </CardBody>
      </Card>
    </>
  );
}
