import { SET_MINICATEGORY } from '../actionTypes';

const initialState = {
  allMinicategory: []
};

const minicategoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_MINICATEGORY:
      return { ...state, allMinicategory: payload };
    default:
      return state;
  }
};

export default minicategoryReducer;
