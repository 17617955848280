import { all } from 'redux-saga/effects';
import { WatcherMainApp } from '../redux/app';
import { WatcherAuthApp } from '../redux/auth';
import { WatcherBrandsApp } from '../redux/brands';
import { WatcherCategoryApp } from '../redux/category';
import { WatcherMinicategoryApp } from '../redux/minicategory';
import { WatchProductApp } from '../redux/product';
import { WatcherSellerApp } from '../redux/sellers';
import { WatcherSubcategoryApp } from '../redux/subcategory';
import { WatcherFileUploadApp } from '../redux/fileUpload';

export default function* rootSagas() {
  yield all([
    WatcherMainApp(),
    WatcherAuthApp(),
    WatcherCategoryApp(),
    WatcherSubcategoryApp(),
    WatcherMinicategoryApp(),
    WatcherBrandsApp(),
    WatchProductApp(),
    WatcherSellerApp(),
    WatcherFileUploadApp()
  ]);
}
